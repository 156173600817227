import userTemplate from './user.html';
import userDialogTemplate from './userdialog.html';
import confirmDialogTemplate from './confirmDialog.html';

import '../../avatar/avatar1.jpg';
import '../../avatar/avatar2.jpg';
import '../../avatar/avatar3.jpg';
import '../../avatar/avatar4.jpg';
import '../../avatar/avatar5.jpg';
import '../../avatar/avatar6.jpg';
import '../../avatar/avatar7.jpg';
import '../../avatar/avatar8.jpg';
import '../../avatar/avatar9.jpg';
import '../../avatar/avatar10.jpg';

angular.module('org.saga.usercard', [
   'org.saga.service'
])

   .directive('userCard', [function() {
      return {
         restrict: 'E',
         template: userTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            newMode: '=',
            userChanged: '&',
            editMode: '='
         },
         controller: ['$scope', '$http', 'EditDialog', 'Users', 'HankoUsers', '$translate', 'ConfirmDialog', '$mdDialog', function($scope, $http, EditDialog, Users, HankoUsers, $translate, ConfirmDialog) {

            $scope.edit = function() {
               const user = angular.copy($scope.ngModel);
               EditDialog.show(user, $scope.newMode, $scope.editMode, function(result) {
                  Users.save(user, function(u) {
                     $scope.userChanged({ user: $scope.ngModel });
                  });
               });
            };

            $scope.delete = function() {
               const user = angular.copy($scope.ngModel);

               HankoUsers.query({}, function(data) {
                  const userByEmail = data.find(localUser => {
                     return localUser.emails.find(email => email.address === user.email);
                  });
                  user.hankoId = userByEmail.id;
               }, function(error) {
                  console.log(error);
               });

               ConfirmDialog.show(user, function() {
                  Users.remove({ id: user.id }, function() {
                     HankoUsers.remove({ id: user.hankoId }, function() {
                        $scope.userChanged({ user: $scope.ngModel });
                     });
                  });
               });
            };
         }]
      };
   }])

   .factory('EditDialog', ['$mdDialog', function($mdDialog) {

      return {
         show: function(user, newMode, editMode, callback) {

            $mdDialog.show({
               template: userDialogTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true,
               controller: ['$scope', '$log', '$mdDialog', '$translate', 'Roles', function($scope, $log, $mdDialog, $translate, Roles) {

                  $scope.namePattern = '[a-zA-Z0-9_@\\.]+';
                  $scope.newRoleName = '';
                  $scope.newRoleManagerName = '';

                  if (newMode) {
                     $scope.avatar = Math.floor(Math.random() * 10) + 1;
                  } else {
                     if (user.imageUrl) {
                        var filename = user.imageUrl.substring(user.imageUrl.lastIndexOf('/') + 1);
                        console.log(filename);
                        $scope.avatar = filename.substring(6, 7);
                        console.log($scope.avatar);
                     }
                  }
                  $scope.newMode = newMode;

                  $scope.user = user;
                  $scope.userRoles = { SYSTEM: [], PROJECT: [], MANAGER: [] };
                  $scope.roles = { SYSTEM: [], PROJECT: [], MANAGER: [] };
                  Roles.query(function(roles) {
                     angular.forEach(roles, function(role) {
                        $scope.roles[role.type].push(role.name);
                        angular.forEach(user.roles, function(r) {
                           if (r.name === role.name) {
                              $scope.userRoles[role.type].push(role.name);
                              return true;
                           }
                        });
                     });
                  });

                  $scope.displayRoles = function(roles) {
                     if (roles && roles.length > 5) {
                        return $translate.instant('RolesCounts', { count: roles.length });
                     }
                     return roles.join(', ');
                  };

                  $scope.isManager = function() {
                     return ($scope.userRoles.SYSTEM.indexOf('MANAGER') >= 0);
                  };

                  $scope.ok = function() {
                     user.roles = [];
                     $scope.user.imageUrl = 'avatar/avatar' + $scope.avatar + '.jpg';
                     angular.forEach($scope.userRoles, function(list) {
                        angular.forEach(list, function(roleName) {
                           user.roles.push({ name: roleName });
                        });
                     });
                     $mdDialog.hide();
                     callback(user);
                  };

                  $scope.cancel = function() {
                     $mdDialog.hide();
                  };

                  $scope.startProjectNewRole = function() {
                     $scope.newProjectRole = !$scope.newProjectRole;
                     $scope.newManagerRole = false;
                     $scope.newRoleName = '';
                  };

                  $scope.startManagerNewRole = function() {
                     $scope.newManagerRole = !$scope.newManagerRole;
                     $scope.newProjectRole = false;
                     $scope.newRoleManagerName = '';
                  };

                  $scope.addRole = function(type) {
                     $scope.newProjectRole = false;
                     $scope.newManagerRole = false;
                     var r = (type === 'PROJECT') ? $scope.newRoleName : $scope.newRoleManagerName;
                     Roles.save({ name: r, label: r, type: type }, function(role) {
                        $scope.roles[type].push(r);
                     });
                  };

               }]
            });

         }
      };
   }])

   .factory('ConfirmDialog', ['$mdDialog', function($mdDialog) {

      return {
         show: function(user, callback) {

            $mdDialog.show({
               template: confirmDialogTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true,
               controller: ['$scope', '$log', '$mdDialog', '$translate', function($scope, $log, $mdDialog, $translate) {

                  $scope.user = user;

                  $scope.ok = function() {
                     $mdDialog.hide();
                     callback(user);
                  };

                  $scope.cancel = function() {
                     $mdDialog.hide();
                  };
               }]
            });

         }
      };
   }]);
