import flowTemplate from './flow.html';
import Highcharts from 'highcharts/highcharts';
import 'angular-google-chart';

angular.module('org.saga.flow', [
   'org.saga.service',
   'googlechart'
])

   .factory('FlowDialog', ['$mdDialog', '$log', 'Role', '$timeout', 'IntervalFactory', 'HumanTaskFlow', function($mdDialog, $log, Role, $timeout, IntervalFactory, HumanTaskFlow) {

      return {
         show: function(role, interval, shift, callback) {
            function DialogController($scope, $log, $mdDialog) {


               $scope.roleName = role;
               Role.get({ name: $scope.roleName }, function(role) {
                  $scope.role = role;
               });
               $scope.shift = shift;
               $scope.interval = interval;

               var request = IntervalFactory.build($scope.shift, $scope.interval);

               HumanTaskFlow.role({ role: $scope.roleName }, request, function(data) {

                  var dates = [];
                  var inData = [];
                  angular.forEach(data.IN.cells, function(cell) {
                     dates.push(cell.name);
                     inData.push(cell.count);
                  });
                  var outData = [];
                  angular.forEach(data.OUT.cells, function(cell) {
                     outData.push(-cell.count);
                  });

                  var diffData = [];
                  angular.forEach(data.DIFF.cells, function(cell) {
                     diffData.push(cell.count);
                  });

                  let chartConfig = {
                     chart: {
                        type: 'container',
                        backgroundColor: 'transparent',
                        renderTo: 'chart1',
                        height: '500px',
                        width: 800,
                        reflow: true
                     },

                     title: null,
                     xAxis: {
                        categories: dates
                     },
                     series: [{
                        type: 'column',
                        name: 'In',
                        data: inData
                     }, {
                        type: 'column',
                        name: 'Out',
                        data: outData
                     }, {
                        type: 'spline',
                        name: 'Difference',
                        data: diffData,
                        marker: {
                           lineWidth: 2,
                           lineColor: 'red',
                           fillColor: 'white'
                        }
                     }]
                  };

                  $timeout(
                     function() {
                        $scope.chart = Highcharts.chart('chart1', chartConfig);
                     }, 200);

               });


               $scope.ok = function() {
                  $mdDialog.cancel();
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: flowTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
