import taskCardTemplate from './taskcard.html';

angular.module('org.saga.taskcard', [
   'org.saga.service',
   'org.saga.delegate',
   'org.saga.clipboard'
])

   .directive('taskCard', ['$log', 'AuthSharedService', 'HumanTaskOperation', 'DelegateDialog', 'Identity', 'TaskUrl', 'Session', 'SuspendDialog',
      function($log, AuthSharedService, HumanTaskOperation, DelegateDialog, Identity, TaskUrl, Session, SuspendDialog) {
         return {
            restrict: 'E',
            template: taskCardTemplate,
            controllerAs: 'ctrl',
            scope: {
               ngModel: '=',
               options: '=',
               tableOptions: '=',
               refreshFn: '&'
            },
            controller: ['$scope', '$location', function($scope, $location) {

               $scope.onClick = function() {
                  if ($scope.options && $scope.options.selectionEnabled) {
                     $scope.ngModel.selection = !$scope.ngModel.selection;
                     return;
                  }

                  if ($scope.options && $scope.options.openDisabled) {
                     return;
                  }

                  $location.path(TaskUrl.get($scope.ngModel.id));
               };

               $scope.isManager = function() {
                  const businessAdministrator = (Session.userRoles && Session.userRoles.indexOf($scope.ngModel.businessAdministrator.name) >= 0);
                  return AuthSharedService.isAuthorized('MANAGER') && businessAdministrator;
               };

               $scope.isTaskStarted = $scope.ngModel.status === 'IN_PROGRESS' || $scope.ngModel.status === 'SUSPENDED';

               if ($scope.tableOptions.showOwner && $scope.ngModel.owner) {
                  Identity.get({ name: $scope.ngModel.owner.name }, function(u) {
                     $scope.displayOwnerName = u.displayName;
                  });
               }

               $scope.urge = function() {
                  var hto = new HumanTaskOperation();
                  hto.id = $scope.ngModel.id;
                  hto.operation = 'URGE';
                  hto.$update(function() {
                     $log.info('task ' + $scope.ngModel.id + ' urged');
                     $scope.refreshFn();
                  }, function(error) {
                     $log.info('error urging the task ' + $scope.ngModel.id + ': ' + error);
                  });
               };

               $scope.dissuade = function() {
                  var hto = new HumanTaskOperation();
                  hto.id = $scope.ngModel.id;
                  hto.operation = 'DISSUADE';
                  hto.$update(function() {
                     $log.info('task ' + $scope.ngModel.id + ' dissuaded');
                     $scope.refreshFn();
                  }, function(error) {
                     $log.info('error dissuading the task ' + $scope.ngModel.id + ': ' + error);
                  });
               };

               $scope.suspend = function() {
                  SuspendDialog.show(function(suspendedUntil) {
                     var hto = new HumanTaskOperation();
                     hto.id = $scope.ngModel.id;
                     hto.suspension = { basedOn: 'VALUE', value: suspendedUntil };
                     hto.operation = 'SUSPEND';
                     hto.$update(function() {
                        $log.info('task suspended');
                        $scope.refreshFn();
                     }, function(error) {
                        $log.info('error suspending the task ' + $scope.ngModel.id + ': ' + error);
                     });
                  });
               };

               $scope.delegate = function() {
                  DelegateDialog.show([$scope.ngModel], function(newOwner) {
                     var hto = new HumanTaskOperation();
                     hto.id = $scope.ngModel.id;
                     hto.operation = 'DELEGATE';
                     hto.newOwner = newOwner;
                     hto.$update(function() {
                        $log.info('task ' + $scope.ngModel.id + ' delegated to ' + newOwner.name);
                        $scope.refreshFn();
                     }, function(error) {
                        $log.info('error delegating the task ' + $scope.ngModel.id + ': ' + error);
                     });
                  });
               };

               $scope.openOverview = () => $location.path('/overview/' + $scope.ngModel.instance.instanceId);

               $scope.buttonLabel = () => {
                  if ($scope.options && $scope.options.selectionEnabled) {
                     return $scope.ngModel.selection ? 'Unselect' : 'Select';
                  }

                  if ($scope.options && $scope.options.openDisabled) {
                     return;
                  }

                  return $scope.isTaskStarted ? 'Continue' : 'Take';
               };

            }]
         };
      }]);
