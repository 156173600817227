import addIdentityTemplate from './addidentity.html';

angular.module('org.saga.addidentity', [
   'org.saga.service'
])

   .directive('addIdentity', function() {
      return {
         restrict: 'E',
         template: addIdentityTemplate,
         scope: {
            ngModel: '=',
            onSelect: '&',
            labels: '='
         },
         controller: ['$scope', function($scope) {
            $scope.loading = false;
            $scope.selectIdentity = function(identity) {
               $scope.onSelect({ identity: identity });
            };

         }]
      };
   });
