import delegateTemplate from './delegate.html';

angular.module('org.saga.delegate', [
   'org.saga.service'
])

   .factory('DelegateDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(humantasks, callback) {
            function DialogController($scope, $log, $mdDialog, Identity, Role) {

               $scope.identities = [];

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $log.info($scope.newOwner);
                  $mdDialog.hide($scope.newOwner);
               };

               $scope.queryIdentities = function(searchText) {
                  if (searchText && searchText.length > 0) {
                     angular.forEach(humantasks, function(ht) {
                        var excludingUsers = (!ht.excludedOwner) ? { excludingUsers: [] } : { excludingUsers: [ht.excludedOwner] };
                        Role.search({
                           name: ht.potentialOwner.name,
                           query: searchText
                        }, excludingUsers, function(results) {
                           $scope.identities = results;
                        });
                     });
                  }
               };

               $scope.labelIdentity = function(identity) {
                  return identity ? identity.displayName + ' (' + identity.email + ')' : undefined;
               };

               $scope.imageIdentity = function(identity) {
                  return identity.imageUrl;
               };

               $scope.selectNewOwner = function(identity) {
                  $scope.newOwner = identity;
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', 'Identity', 'Role', DialogController],
               template: delegateTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
