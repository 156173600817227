angular.module('org.saga.values', [])

	// Application configuration
    .constant('Configuration', {

		// Number of items show in lists, e.g. dashboard, new process etc.
		listMaxItems: 100,

		// Important fields lengths
		maxLabelLength: 50,
		maxDescriptionLength: 255,

	})

	// Default widget properties
	.value('DefaultProperties', {
		'account': {
			'bankCode': undefined,
			'showValidateWarnings': 'default',
			'bankCodeEnabled': true,
			'labels': {
				'prefix': 'widget.account.prefix',
				'number': 'widget.account.number',
				"bankCode": "widget.account.bankCode"
			},
			'errors': {
				'theAccountPrefixLess6': "widget.account.theAccountPrefixLess6",
				"notValidPrefix": "widget.account.notValidPrefix",
				"noValidAccountNumber": "widget.account.noValidAccountNumber",
				"thisIsRequired": "widget.account.thisIsRequired",
				"selectExistingBank": "widget.account.selectExistingBank",
				"theAccountNumberBigger10": "widget.account.theAccountNumberBigger10",
				"bankCodeIsRequired": "widget.account.bankCodeIsRequired"
			}
		},
		'button': {
			'url': undefined,
			'urlLabel': 'widget.button.urlLabel',
			'buttonLabel': 'widget.button.buttonLabel',
			'closeSection': false
		},
		'checkbox': {'disabled': {}, 'buttonMode': false, 'items': undefined, 'result': 'LIST'},
		'comments': {'numLimit': 5},
		'company': {
			'copyIcon': true,
			"labels": {
				"taxId": "widget.company.taxId",
				"search": "widget.company.search",
				"companyName": "widget.company.companyName",
				"clear": "widget.company.clear",
				"none": "widget.company.none",
				"nothingFound": "widget.company.nothingFound",
				'thisIsRequired': "widget.company.thisIsRequired"
			}
		},
		"date": {
			"mode": "date",
			"format": "DD.MM.YYYY",
			"placeholder": undefined,
			"minDate": undefined,
			"maxDate": undefined,
			"watching": [],
			"disablePast": undefined,
			"disableFuture": undefined,
			"weekDays": false,
		},
		'documentlist': {
			'header': undefined,
			'copyIcon': true,
			'filter': undefined,
			'defaultType': undefined,
			'allowedTypes': undefined,
			'force': true,
			'watching': [],
			'autoPreview': false,
			"actions": [
				"add",
				"update",
				"download",
				"delete",
				"refresh",
				"newWindow"
			],
			"labels": {
				"documentName": "widget.documentlist.documentName",
				"documentType": "widget.documentlist.documentType",
				"documentTitle": "widget.documentlist.documentTitle",
				"documentDescription": "widget.documentlist.documentDescription",
				"thisIsRequired": "widget.documentlist.thisIsRequired",
				"add": "widget.documentlist.add",
				"upload": "widget.documentlist.upload",
				"download": "widget.documentlist.download",
				"delete": "widget.documentlist.delete",
				"newWindow": "widget.documentlist.newWindow",
				"refresh": "widget.documentlist.refresh",
				"update": "widget.documentlist.update",
				"preview": "widget.documentlist.preview",
				"hidePreview": "widget.documentlist.hidePreview",
				"file": "widget.documentlist.file",
				"undefined": "widget.documentlist.undefined",
			},
			"documentDetail": "Vytvořen {{createdAt | date:'medium'}}",
			tableColumnNames: [
				"widget.documentlist.tableColumnNames.documentType",
				"widget.documentlist.tableColumnNames.documentId",
				"widget.documentlist.tableColumnNames.documentName",
				"widget.documentlist.tableColumnNames.documentDescription"
			]
		},
		'evaluator': {'expression': '', 'watching': []},
		'filter': {'input': undefined, 'expression': undefined, outputItemExpression: 'item', 'watching': []},
      'history': {'header': "widget.history.header", 'show': {'timeline': true, 'table': true}},
		'identity': {
			'copyIcon': true,
			'setLoggedUser': 'false',
			'labels': {
				"search": "widget.identity.search",
				"none": "widget.identity.none",
				"nameOrEmail": "widget.identity.nameOrEmail",
				"clear": "widget.identity.clear",
				"nothingFound": "widget.identity.nothingFound",
				"thisIsRequired": "widget.identity.thisIsRequired"
			}
		},
		'instancelist': {
            'query': null,
            'watching': [],
            'displayCurrent': false,
            'showFilter': false
        },
		'map': {'scrollWheel': true, 'zoom': 14, 'streetView': false},
		'modellist': {
            'tableHeader': 'widget.history.header',
            'filter': undefined,
            'closeSection': false
        },
		'number': {
			'max': undefined,
			'showValidateWarnings': 'default',
			'placeholder': undefined,
			'min': undefined,
			'errors': {
				"numberIsLesser": "widget.number.numberIsLesser",
				"numberIsHigher": "widget.number.numberIsHigher"
			}
		},
		'person': {
			'copyIcon': true,
			'personIdentification': '{{degree}} {{firstName}} {{surname}}',
			'labels': {
				"search": "widget.person.search",
				"clear": "widget.person.clear",
				"crmId": "widget.person.crmId",
				"personalId": "widget.person.personalId",
				"idCard": "widget.person.idCard",
				"nothingFound": "widget.person.nothingFound",
				"none": "widget.person.none",
				"elite": "widget.person.elite",
				'thisIsRequired': "widget.person.thisIsRequired"
			}
		},
		'product': {
			'filter': undefined,
			'copyIcon': true,
			'labels': {
				"owner": "widget.product.owner",
				"accessible": "widget.product.accessible",
				"search": "widget.product.search",
				"clear": "widget.product.clear",
				"none": "widget.product.none",
				"nothingFound": "widget.product.nothingFound",
				"productIdentification": "widget.product.productIdentification",
				"tableHeader": "widget.product.tableHeader",
				"unknown": "widget.product.unknown",
				'thisIsRequired': "widget.product.thisIsRequired"
			}
		},
		'productlist': {'tableHeader': 'widget.productlist.tableHeader', 'filter': undefined},
		'radio': {
            'disabled': {},
            'buttonMode': false,
            'closeSection': false,
            'items': undefined
        },
		'rest': {
			"urlTemplate": undefined,
			"closeSection": false,
			"template": undefined,
			"headers": {},
			"local": false,
			"method": undefined,
			"autorun": false,
			"hideButton": false,
			"watching": [],
			labels: {
				"ok": "widget.rest.ok",
				"error": "widget.rest.error",
				"none": "widget.rest.none",
				"running": "widget.rest.running"
			}
		},
		'generatePDF': {
			"templateId": undefined,
			"documentType": {},
			"fileName": undefined,
			"watching": [],
			labels: {
				"ok": "widget.rest.ok",
				"error": "widget.rest.error",
				"none": "widget.rest.none",
				"running": "widget.rest.running"
			}
		},
		'richtext': {},
		'robot': {
			'playButtonLabel': 'Play',
			'autoplay': false,
			'scriptName': undefined,
			'url': undefined
		},
		'select': {
			'enumeration': undefined,
			filter: undefined,
			'showValidateWarnings': 'default',
			'placeholder': undefined,
			'items': undefined,
			'keyExpression': undefined,
			'labelExpression': undefined,
			'errors': {'thisIsRequired': "widget.select.thisIsRequired"},
			'emptyValuePlaceholder': 'widget.select.emptyValuePlaceholder',
			'multiple': false,
		},
		'showhtml': {'template': undefined, 'watching': [], 'frame': false},
		'text': {
			'length': undefined,
			'showValidateWarnings': 'default',
			'pattern': undefined,
			'placeholder': undefined,
			'errors': {
				'thisIsRequired': "widget.text.thisIsRequired",
				"textIsTooLong": "widget.text.textIsTooLong",
				"wrongPattern": "widget.text.wrongPattern",
				"email": "widget.text.email"
			},
			'type': 'text',
			'rows': 1,
		},
		'timesheet': {
			'allowProject': true,
			'allowSubproject': true,
			'allowActivityType': true,
			'projectRequired': false,
			'subprojectRequired': false,
			'activityTypeRequired': false,
			'projects': [],
			'subprojects': [],
			'subprojectsExpression': null,
			'activityTypes': [],
			'projectLabelExpression': '',
			'subprojectLabelExpression': '',
			'activityTypeLabelExpression': '',
			'filterProjectsExp': null,
			'filterSubprojectsExp': null,
			'filterActivityTypesExp': null,
			'minDate': undefined,
			'maxDate': undefined,
			'labels': {
				'date': 'widget.timesheet.date',
				'timeFrom': 'widget.timesheet.timeFrom',
				'timeTo': 'widget.timesheet.timeTo',
				'project': 'widget.timesheet.project',
				'subproject': 'widget.timesheet.subproject',
				'activityType': 'widget.timesheet.activityType',
				'description': 'widget.timesheet.description',
				'error': 'widget.timesheet.error',
				'timeRangeError': 'widget.timesheet.timeRangeError',
			}
		}
	})

	.value('ExampleProperties', {
		'checkbox': {'items': {"yes": "Yes", "no": "No"}},
		'radio': {'items': {"yes": "Yes", "no": "No"}},
		'select': {
			'items': [{"key": "A", "value": "Value A"}, {"key": "B", "value": "Value B"}],
			'keyExpression': "item.key",
			'labelExpression': "item.value"
		},
		'showhtml': {'template': "<i>Freemarker template</i>"}
	})

	.value('WidgetIcons', {
		'account': 'attach_money',
		'button': 'check',
		'checkbox': 'check_box',
		'comments': 'chat',
		'company': 'business',
		'date': 'date_range',
		'documentlist': 'note',
		'evaluator': 'exposure_plus_1',
		'history': 'history',
		'identity': 'face',
		'instancelist': 'swap_calls',
		'modellist': 'receipt',
		'number': 'filter_1',
		'person': 'person',
		'product': 'credit_card',
		'productlist': 'account_balance',
		'radio': 'radio_button_checked',
		'rest': 'play_arrow',
		'richtext': 'subject',
		'robot': 'android',
		'select': 'storage',
		'showhtml': 'code',
		'text': 'font_download',
		'map': 'place'
	})

	.value('ProductImages', {
		'A': 'account_balance_wallet',
		'C': 'credit_card',
		'I': 'assessment',
		'L': 'monetization_on',
		'S': 'group_work',
		'X': 'help'
	})

	.value('IdentityImages', {
		'yes': 'assignment_ind',
		'no': 'help'
	})
;
