import dashboardTemplate from './dashboard/dashboard.html';
import operationsTemplate from './operations/operations.html';
import screenTemplate from './screen/screen.html';
import taskInfoTemplate from './taskinfo/taskinfo.html';
import diagramTemplate from './diagram/diagram.html';
import sectionDesignerTemplate from './diagram/sectiondesigner.html';
import newInstanceTemplate from './newinstance/newinstance.html';
import queuesTemplate from './queues/queues.html';
import flowTemplate from './comp/flow/flow.html';
import teamTemplate from './team/team.html';
import designTemplate from './design/design.html';
import designHistoryTemplate from './design/designhistory.html';
import diagramSchemaTemplate from './diagram/schema.html';
import widgetListTemplate from './documentation/widgetlist.html';
import widgetTemplate from './documentation/widget.html';
import projectTemplate from './configuration/project.html';
import usersTemplate from './users/users.html';
import clientClaimTemplate from './clientClaim/clientClaim.html';

export const routes = {
   dashboard: {
      template: dashboardTemplate,
      controller: 'DashboardController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   operations: {
      template: operationsTemplate,
      controller: 'OperationsController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   operationsQuery: {
      template: operationsTemplate,
      controller: 'OperationsController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   task: {
      template: screenTemplate,
      controller: 'TaskController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER', 'CLIENT']
      }
   },
   taskMode: {
      template: screenTemplate,
      controller: 'TaskController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER', 'CLIENT']
      }
   },
   taskClient: {
      template: clientClaimTemplate,
      controller: 'ClaimClientTaskController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['CLIENT']
      }
   },
   taskinfo: {
      template: taskInfoTemplate,
      controller: 'TaskInfoController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER', 'CLIENT']
      }
   },
   diagram: {
      template: diagramTemplate,
      controller: 'DiagramController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   diagramTaskName: {
      template: diagramTemplate,
      controller: 'DiagramController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   diagramSectionName: {
      template: sectionDesignerTemplate,
      controller: 'SectionDesignerController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   overview: {
      template: screenTemplate,
      controller: 'OverviewController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   newinstance: {
      template: newInstanceTemplate,
      controller: 'NewInstanceController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   queues: {
      template: queuesTemplate,
      controller: 'QueuesController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['MANAGER']
      }
   },
   queuesRole: {
      template: queuesTemplate,
      controller: 'QueuesController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['MANAGER']
      }
   },
   flow: {
      template: flowTemplate,
      controller: 'FlowController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['MANAGER']
      }
   },
   team: {
      template: teamTemplate,
      controller: 'TeamController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['MANAGER']
      }
   },
   teamMember: {
      template: teamTemplate,
      controller: 'TeamController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['MANAGER']
      }
   },
   design: {
      template: designTemplate,
      controller: 'DesignController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   designHistory: {
      template: designHistoryTemplate,
      controller: 'DesignHistoryController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   schema: {
      template: diagramSchemaTemplate,
      controller: 'SchemaController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   documentation: {
      template: widgetListTemplate,
      controller: 'WidgetListController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   documentationWidget: {
      template: widgetTemplate,
      controller: 'WidgetController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['DESIGNER']
      }
   },
   configuration: {
      template: projectTemplate,
      controller: 'ProjectConfigurationController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['ADMIN']
      }
   },
   users: {
      template: usersTemplate,
      controller: 'UsersController',
      controllerAs: 'ctrl',
      access: {
         loginRequired: true,
         authorizedRoles: ['USER']
      }
   },
   loginSuccessful: {
      redirectTo: '/dashboard'
   }
};
