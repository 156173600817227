import diagramNavTemplate from './diagramnav.html';

angular.module('org.saga.diagramnav', [
   'org.saga.service'
])

   .directive('diagramNav', ['Model', 'NewInstance', '$location', function(Model, NewInstance, $location) {
      return {
         restrict: 'E',
         template: diagramNavTemplate,
         transclude: true,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            showBack: '=',
            visibleAdd: '=',
            enableAdd: '=',
            visibleEdge: '=',
            enableShow: '=',
            goToScreen: '&goToScreen',
            goToSection: '&goToSection',
            task:'=',
            back: '&back',
            add: '&add',
            save: '&save',
            edge: '&edge'
         },
         controller: ['$scope', function($scope) {
            $scope.addLabel = $scope.task ? "AddSection" : "AddTask";
            $scope.addTitle = $scope.task ? "Task" : "Model";

            $scope.edgeClicked = function() {
               $scope.edgeModeActive = !$scope.edgeModeActive;
               $scope.edge({edgeMode: $scope.edgeModeActive});
            };

            $scope.createInstance = function() {
               NewInstance.run($scope.ngModel, function(url) {
                  $location.path(url);
               });
            };

         }]
      };
   }]);
