angular.module('org.saga.team', [
   'org.saga.service',
   'angularResizable'
])

   .controller('TeamController', ['$scope', 'Manager', '$translate', '$log', '$location', '$routeParams', 'Absent', '$rootScope', 'Role', 'IdentityRoles', '$mdDialog', 'DelegateDialog', 'Team', 'TaskOps',
      function($scope, Manager, $translate, $log, $location, $routeParams, Absent, $rootScope, Role, IdentityRoles, $mdDialog, DelegateDialog, Team, TaskOps) {

         $scope.member = null;
         $scope.showRolesImmediately = false;
         if ($routeParams.member) {
            $scope.member = $routeParams.member;
            $scope.showRolesImmediately = true;
         }

         $scope.graphsData = { user: $rootScope.account.name };
         $scope.members = Manager.team();

         $scope.allRoles = Role.query();

         $scope.userTasks = null;

         $scope.tableOptions = { showOwner: false };

         $scope.getAbsentMessage = function(a) {
            return (a) ? 'IsAbsent' : 'NotAbsent';
         };

         $scope.absent = false;

         $scope.onChangeAbsence = function(a) {
            if (a) {
               Absent.save({ user: $scope.member }, {});
            } else {
               Absent.delete({ user: $scope.member });
            }
         };

         $scope.refreshUserTask = function(member) {
            if (member) {
               Team.getUserTasks({ username: member }, function(list) {
                  $scope.userTasks = list;
               });
               Absent.get({ user: member }, function(result) {
                  $scope.absent = result.value;
               });
            } else {
               $scope.userTasks = null;
               $scope.absent = false;
            }
         };

         $scope.refreshMemberTasks = function() {
            $scope.refreshUserTask($scope.member);
         };

         $scope.releaseAll = function() {
            var ids = [];
            angular.forEach($scope.userTasks, function(ht) {
               if (ht.selection) {
                  ids.push(ht.id);
               }
            });
            TaskOps.releaseAll({}, { ids: ids }, function() {
               $scope.refreshUserTask($scope.member);
            });
         };

         $scope.urgeAll = function() {
            var ids = [];
            angular.forEach($scope.userTasks, function(ht) {
               if (ht.selection) {
                  ids.push(ht.id);
               }
            });
            TaskOps.urgeAll({}, { ids: ids }, function() {
               $scope.refreshUserTask($scope.member);
            });
         };

         $scope.delegateAll = function() {
            var hts = [];
            var ids = [];
            angular.forEach($scope.userTasks, function(ht) {
               if (ht.selection) {
                  hts.push(ht);
                  ids.push(ht.id);
               }
            });
            DelegateDialog.show(hts, function(newOwner) {
               TaskOps.delegateAll({ username: newOwner.name }, { ids: ids }, function() {
                  $scope.refreshUserTask($scope.member);
               }, function(error) {
                  if (error.status === 403) {
                     $mdDialog.show(
                        $mdDialog.alert()
                           .clickOutsideToClose(true)
                           .title($translate.instant('BulkDelegateError'))
                           .textContent($translate.instant('BulkDelegateErrorAccessDenied'))
                           .ok($translate.instant('Ok'))
                     );
                  } else {
                     $mdDialog.show(
                        $mdDialog.alert()
                           .clickOutsideToClose(true)
                           .title($translate.instant('BulkDelegateError'))
                           .textContent(error.data.value)
                           .ok($translate.instant('Ok'))
                     );
                  }
               });
            });

         };


         $scope.updateGraph = function(member) {
            $scope.graphsData = { user: member };

            $scope.roles = IdentityRoles.query({ name: member });
            $scope.refreshUserTask(member);
         };

         if ($scope.showRolesImmediately) {
            $scope.updateGraph($scope.member);
         }

         $scope.getRoleLabel = function(role) {
            var result = '';
            angular.forEach($scope.allRoles, function(r) {
               if (r.name === role.name) {
                  result = r.label;
                  return false;
               }
            });
            return result;
         };

         $scope.showQueue = function(role) {
            $location.path('/queues/' + role.name);
         };

         $scope.updateRoles = function() {
            IdentityRoles.update({ name: $scope.member }, $scope.roles, function() {

               var alert = $mdDialog.alert()
                  .parent(angular.element(document.body))
                  .clickOutsideToClose(true)
                  .title('Active Roles updated')
                  .textContent('Active Roles was successfully updated for the selected member.')
                  .ariaLabel('Active Roles')
                  .ok('OK');

               $mdDialog.show(alert).then(function() {
                  // do nothing
               });
            });
         };

         $scope.refreshUserTask();

      }]);
