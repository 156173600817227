import moment from 'moment';
import { isValid } from 'date-fns';
import dateTemplate from './date.html';

angular.module('org.saga.widget.date', [
   'org.saga.service',
   'ngMaterialDatePicker'
])

   .directive('dateWidget', ['PropertiesLoader', '$translate', 'mdcDefaultParams', function(PropertiesLoader, $translate, mdcDefaultParams) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         require: '^form',
         template: dateTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            value: '=?',
            time: '=?'
         },
         link: (scope, element, attrs, form) => {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', 'Utils', function($scope, DefaultProperties, Utils) {
            PropertiesLoader.load($scope, DefaultProperties.date);

            let timeoutContext = { timeout: null };

            const {
               mode,
               minDate,
               maxDate,
               disablePast,
               disableFuture
            } = $scope;

            $scope.showTime = mode === 'date-time';

            if (disablePast) {
               $scope.getMinDate = new Date();
            }

            if (disableFuture) {
               $scope.getMaxDate = new Date();
            }

            const getDateFromAttribute = (path) => {
               const attrVal = Utils.getObjectAttribute($scope.instance.attributes, path);
               const date = new Date(attrVal);
               return isValid(date) ? date : undefined;
            };

            const getDate = (value) => {
               const date = new Date(value);
               return isValid(date) ? date : getDateFromAttribute(value);
            };

            $scope.setMinAndMaxDate = () => {
               if (!!minDate) {
                  $scope.getMinDate = getDate(minDate);
               }
               if (!!maxDate) {
                  $scope.getMaxDate = getDate(maxDate);
               }
            };

            if ($scope.value && typeof ($scope.value) == 'string') {
               $scope.value = new Date($scope.value);
            }

            if ($scope.time && !$scope.value) {
               $scope.value = new Date($scope.time);
            }

            const lang = navigator.language;
            mdcDefaultParams({
               lang: lang,
               cancelText: $translate.instant('Cancel'),
               okText: $translate.instant('Ok')
            });

            $scope.weekDay = Number($translate.instant('WeekStartDay'));

            $scope.setMinAndMaxDate();

            $scope.$watch('value', (val) => {
               if (val && typeof (val) === 'string') {
                  $scope.time = Date.parse(val);
               } else if (moment.isMoment(val)) {
                  $scope.time = val.valueOf();
               } else if (val && typeof (val) === 'object') {
                  $scope.time = val.getTime();
               }
               $scope.value = val;
            });

            angular.forEach($scope.watching, function(w) {
               $scope.$watch('instance.attributes.' + w,
                  Utils.lateWatch(timeoutContext, 200, $scope.setMinAndMaxDate));
            });
         }]
      };
   }]);
