angular.module('org.saga.configuration.project', [
   'org.saga.service',
   'org.saga.addmailboxconfig'
])


   .controller('ProjectConfigurationController', ['$scope', '$log', '$mdDialog', 'AddMailboxConfigDialog', 'MailboxConfig', 'Mailbox',
      function($scope, $log, $mdDialog, AddMailboxConfigDialog, MailboxConfig, Mailbox) {

         $scope.addMailboxConfiguration = function() {
            AddMailboxConfigDialog.show({}, function(mailboxConfig) {
               MailboxConfig.save(mailboxConfig, function() {
                  $scope.mailboxConfigs = MailboxConfig.query();
               });
            });
         };

         $scope.fetch = function() {
            Mailbox.fetch({}, function() {
               // this is ok
            });
         };

         $scope.mailboxConfigs = MailboxConfig.query();

      }]);

