import customizeTemplate from './customize.html';
import 'angular-drag-and-drop-lists';

angular.module('org.saga.customize', [
   'dndLists',
   'org.saga.service',
   'org.saga.widgetdetail'
])

   .factory('CustomizeSectionDialog', ['$mdDialog', 'Utils', function($mdDialog, Utils) {
      return {
         show: (widgets, callback) => {

            let realWidgets = widgets;

            $mdDialog.show({
               template: customizeTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true,
               controller: ['$scope', '$log', '$mdDialog', function($scope, $log, $mdDialog) {

                  $scope.widgets = angular.copy(widgets);
                  $scope.selectedWidget = $scope.widgets && $scope.widgets.length ? $scope.widgets[0] : undefined;

                  $scope.widgetName = (fullWidgetName) => {
                     if (!fullWidgetName) {
                        return '';
                     }
                     const i = fullWidgetName.indexOf('-');
                     return fullWidgetName.substring(0, i);
                  };

                  $scope.selectWidget = (widget) => $scope.selectedWidget = widget;

                  $scope.isWidgetSelected = (widget) => $scope.selectedWidget.name === widget.name;

                  $scope.remove = (widget) => {
                     const idx = $scope.widgets.indexOf(widget);
                     if (idx > -1) {
                        $scope.widgets.splice(idx, 1);
                     }
                  };

                  $scope.done = () => {
                     realWidgets.length = 0; // Being able to set the length on an array is bizarre but it is also the absolute fastest way to clear an array in JavaScript
                     angular.forEach($scope.widgets, function(w) {
                        w.binding = Utils.cleanUp(w.binding);
                     });
                     angular.copy($scope.widgets, realWidgets);
                     $mdDialog.hide();
                     callback();
                  };

                  $scope.cancel = () => $mdDialog.hide();

               }]
            });
         }
      };
   }]);
