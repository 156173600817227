import toolbarTemplate from './toolbar.html';

angular.module('org.saga.toolbar', [
   'ngMaterial',
   'org.saga.service'
])

   .directive('rootToolbar', ['$location', '$log', '$rootScope', '$mdSidenav',
      function($location, $log, $rootScope, $mdSidenav) {
         return {
            restrict: 'E',
            transclude: true,
            controllerAs: 'ctrl',
            template: toolbarTemplate,
            scope: {
               title: '@',
               searchVisible: '=',
               sidenavHidden: '=',
               profileHidden: '='
            },
            link: function(scope, elem, attr, ctrl, $transclude) {
               $transclude(function(clone) {
                  if (!clone.length) {
                     scope.defaultContent = true;
                  }
               });
            },
            controller: ['$scope', '$rootScope', '$location', function($scope, $rootScope, $location) {

               $scope.options = {};
               $scope.searchBoxVisible = false;

               $scope.accountImageUrl = function() {
                  return $rootScope.account ? $rootScope.account.imageUrl : undefined;
               };

               $scope.toggleSidenav = function() {
                  $mdSidenav('left-side-nav').toggle();
               };

               $scope.goToUserProfile = function() {
                  location.href = '/v2/profile';
               };

               $scope.clearSearch = function() {
                  $scope.options.query = '';
                  window.setTimeout(function() {
                     document.getElementById('search-instances-box').focus();
                  }, 200);
               };

               $scope.performSearch = function() {
                  $location.path('/operations/' + $scope.options.query);
               };

            }]
         };
      }]);
