import { DataSet } from 'vis-timeline/standalone';

angular.module('org.saga.design.schema', [
   'org.saga.service',
   'ngVis'
])

   .controller('SchemaController', ['$scope', '$log', 'Model', '$routeParams', '$timeout', function($scope, $log, Model, $routeParams, $timeout) {

      $scope.name = $routeParams.name;
      $scope.uuid = $routeParams.uuid;

      $scope.options = {
         physics: {
            enabled: false
         },
         edges: {
            arrows: 'to',
            dashes: false,
            smooth: false,
            font: { align: 'top' }
         },
         nodes: {
            color: {
               background: '#FFFFFF',
               border: '0079c4',
               highlight: {
                  border: '#0093ee',
                  background: '#FFFFFF'
               }
            },
            labelHighlightBold: false,
            shape: 'box',
            font: {
               color: '#8dabc4',
               multi: 'html',
               face: 'arial',
               align: 'left',
               bold: {
                  color: '#8dabc4'
               }
            }
         },
         layout: {
            hierarchical: {
               direction: 'LR',
               levelSeparation: 250,
               sortMethod: 'directed'
            }
         },
         interaction: { dragNodes: true }
      };

      $scope.events = {
         selectNode: function(event) {
            $scope.$evalAsync(function() {
               $scope.$emit('vis:nodeSelectionChanged', event.nodes);
            });
         },
         deselectNode: function(event) {
            $scope.$evalAsync(function() {
               $scope.$emit('vis:nodeSelectionChanged', event.nodes);
            });
         },
         selectEdge: function(event) {
            $scope.$evalAsync(function() {
               $scope.$emit('vis:edgeSelectionChanged', event.edges);
            });
         },
         deselectEdge: function(event) {
            $scope.$evalAsync(function() {
               $scope.$emit('vis:edgeSelectionChanged', event.edges);
            });
         },
         onload: function(network) {
            $timeout(function() {
               network.fit();
            }, 100);

         }
      };

      var nodes = [];
      var edges = [];
      var index = 0;

      function findNodeByName(nodeName) {
         var node;
         angular.forEach(nodes, function(n) {
            if (n.id == nodeName) {
               node = n;
               return false;
            }
         });

         return node;
      }

      $scope.$on('vis:nodeSelectionChanged', function($event, selectedNodes) {
         $event.stopPropagation(); // Useful, to avoid performance leak
         $scope.selectedNode = selectedNodes ? findNodeByName(selectedNodes[0]) : undefined;
      });

      var selectedNode = function(ctx, values, id) {
         values.color = '#2c4059';
      };

      $scope.buildLabel = function(obj) {
         var result = (obj.elementClass) ? ('<b>' + obj.elementClass + '</b>') : '';
         angular.forEach(obj.properties, function(value, key) {
            if (value.type !== 'LIST' && value.type !== 'OBJECT') {
               if (result !== '') {
                  result = result + '\n';
               }
               result = result + key + ':' + value.type;
            }
         });
         if (result === '') {
            result = obj.type;
         }
         return result;
      };

      $scope.buildItem = function(obj, parentNode, name, isCollection) {
         index++;

         var node = {
            id: 'P' + index,
            label: $scope.buildLabel(obj),
            chosen: { label: selectedNode },
            documentation: obj.documentation,
            widgetName: obj.widgetName
         };
         nodes.push(node);

         if (parentNode) {
            var edge = {
               from: parentNode.id,
               to: 'P' + index,
               label: name,
               arrows: { to: { type: (isCollection) ? 'circle' : 'arrow' } }
            };
            edges.push(edge);
         }

         angular.forEach(obj.properties, function(value, key) {
            if (value.type === 'OBJECT') {
               $scope.buildItem(value, node, key, false);
            }
            if (value.type === 'LIST') {
               $scope.buildItem(value.item, node, key + '*', true);
            }
         });
      };

      Model.schema({ name: $scope.name, uuid: $scope.uuid }, function(schema) {
         $log.info('schema created');
         $scope.buildItem(schema, null, 'root', false);

         Model.get({ name: $scope.name, uuid: $scope.uuid }, function(m) {
            $scope.model = m;
         });

         $scope.data = {
            nodes: new DataSet(nodes),
            edges: new DataSet(edges)
         };
      });

   }]);

