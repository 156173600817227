angular.module('org.saga.overview', [
   'org.saga.service',
   'org.saga.toolbar',
   'org.saga.commands',
   'org.saga.addobject',
   'org.saga.designmenu'
])

   .controller('OverviewController', ['$scope', '$log', '$location', '$routeParams', 'InstanceExpression', 'AuthSharedService', 'Instance', 'Overview', '$timeout', '$mdSidenav', '$rootScope', 'Edge',
      function($scope, $log, $location, $routeParams, InstanceExpression, AuthSharedService, Instance, Overview, $timeout, $mdSidenav, $rootScope, Edge) {

         $scope.instanceId = $routeParams.instanceId;
         $scope.visibleSections = [];
         $scope.addObjectOpen = false;
         $scope.isDesigner = function() {
            return AuthSharedService.isAuthorized('DESIGNER');
         };

         $rootScope.designModel = { designMode: $scope.isDesigner(), showDebug: false };

         $scope.evaluateExpression = InstanceExpression.get;

         $scope.toggleDesignMode = function() {
            $rootScope.designModel.designMode = !$rootScope.designModel.designMode;
            $mdSidenav('left-side-nav').toggle();
         };

         Instance.get({ instanceId: $scope.instanceId }, function(instance) {
            $scope.instance = instance;
            $scope.screenLabel = $scope.instance.description;
            $scope.instance.snapshots = undefined; // force to remove snapshots, because we are in overview where no snapshot should be used
            $log.info('instance loaded for overview ' + $scope.instanceId);
            Overview.get({ name: $scope.instance.name, uuid: $scope.instance.uuid }, function(overview) {
               $scope.screen = overview;
               Edge.init($scope.instance, $scope.screen, null);
            }, function() {
               $scope.showError('OverviewDesignError');
            });
         }, function() {
            $scope.showError('LoadInstanceError');
         });

         $scope.isSectionVisible = function(section) {
            const result = Edge.isVisible(section);
            return result;
         };

         $scope.commandFunction = function(command) {
            $log.info('command = ' + command.type);
            const fn = $scope.possibleCommands[command.type];
            fn();
         };

         function navigateToDashboard() {
            $location.path('/dashboard');
         }

         $scope.close = function() {
            navigateToDashboard();
         };

         $scope.showError = function(message) {
            ErrorToast.show(message, function() {
               navigateToDashboard();
            });
         };

         $scope.saveAndClose = function() {
            const changedAttributes = Utils.getChangedAttributes($scope.instance.attributes, originalAttributes);
            if (Object.keys(changedAttributes).length !== 0) {
               $scope.instance.attributes = changedAttributes;
               $scope.instance.$update(function() {
                  navigateToDashboard();
               }, function() {
                  $scope.showError('SaveInstanceError');
               });
            } else {
               navigateToDashboard();
            }
         };

         $scope.possibleCommands = {
            'COMPLETE': $scope.saveAndClose,
            'CLOSE': $scope.close
         };

         $scope.isFormInvalid = function() {
            return false;
         };

      }]);

