angular.module('org.saga.designhistory', [
   'org.saga.service'
])

   .controller('DesignHistoryController', ['$scope', '$routeParams', 'ModelHistory', 'InstanceGroup', '$mdDialog', 'Migration', '$translate',
      function($scope, $routeParams, ModelHistory, InstanceGroup, $mdDialog, Migration, $translate) {
         $scope.name = $routeParams.name;
         $scope.nameObject = { name: $routeParams.name };

         $scope.options = {};

         $scope.reload = function() {
            ModelHistory.query($scope.nameObject, function(models) {
               $scope.models = models;
               InstanceGroup.search($scope.nameObject, function(instanceCounts) {
                  angular.forEach($scope.models, function(model) {
                     model.instanceCount = { totalCount: 0, activeCount: 0 };
                     angular.forEach(instanceCounts, function(instanceCount) {
                        if (model.name === instanceCount.name && model.uuid === instanceCount.uuid) {
                           model.instanceCount = instanceCount;
                        }
                     });
                  });
               });
            });
         };

         $scope.migratePrompt = function(uuid) {
            var dialog = $mdDialog.prompt()
               .title($translate.instant('ModelMigration'))
               .textContent($translate.instant('EnterUUID'))
               .placeholder('UUID')
               .ariaLabel('UUID')
               .initialValue(uuid)
               .required(true)
               .ok($translate.instant('Ok'))
               .cancel($translate.instant('Cancel'));

            $mdDialog.show(dialog).then(function(result) {
               var migrationRequest = {
                  oldVersion: { name: $scope.name, uuid: uuid },
                  newVersion: { name: $scope.name, uuid: result }
               };
               Migration.run(migrationRequest, function(migrationResult) {

                  $mdDialog.show(
                     $mdDialog.alert()
                        .parent(angular.element(document.body))
                        .clickOutsideToClose(true)
                        .title($translate.instant('MigrationResult'))
                        .textContent($translate.instant('MigrationMessage', { count: migrationResult.count }))
                        .ariaLabel($translate.instant('MigrationResult'))
                        .ok('Ok')
                  ).then(function() {
                     $scope.reload();
                  });
               });
            });
         };

         $scope.reload();

         $scope.clearFilter = function() {
            $scope.options.query = undefined;
            document.getElementById('search-box').focus();
         };
      }]);

