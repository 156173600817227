import changeLabelTemplate from './change-label.html';

angular.module('org.saga.changelabel', [
   'org.saga.service'
])

   .factory('ChangeLabelDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(title, message, initValue, callback) {
            function DialogController($scope, $log, $mdDialog) {

               $scope.title = title;
               $scope.message = message;

               $scope.label = initValue;

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $mdDialog.hide($scope.label);
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: changeLabelTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
