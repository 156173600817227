import taskDesignTemplate from './taskdesign.html';

angular.module('org.saga.taskdesign', [
   'org.saga.service',
   'org.saga.designmenu'
])

   .directive('taskDesign', ['Widget', 'WidgetIcons', function(Widget, WidgetIcons) {
      return {
         restrict: 'E',
         template: taskDesignTemplate,
         transclude: true,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            humantask: '=',
            instance: '=',
            screen: '='
         },
         controller: ['$scope', function($scope) {

            $scope.widgetIcon = function(widgetName) {
               return WidgetIcons[widgetName];
            };

            $scope.widgetlist = Widget.query();

         }]
      };
   }]);
