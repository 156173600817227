import taskHistogramTemplate from './taskhistogram.html';
import 'angular-google-chart';

angular.module('org.saga.taskhistogram', [
   'googlechart'
])

   .directive('taskHistogram', function() {
      return {
         restrict: 'E',
         transclude: true,
         template: taskHistogramTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            height: '@'
         },
         controller: ['$scope', '$location', '$translate', function($scope, $location, $translate) {

            $scope.myChartObject = {};

            $scope.myChartObject.type = 'ColumnChart';

            $scope.myChartObject.data = {
               'cols': [
                  { type: 'string', id: 't', label: $translate.instant('Time') },
                  { type: 'number', id: 'c', label: $translate.instant('Count') }
               ],
               'rows': []
            };

            $scope.myChartObject.options = {
               title: undefined
            };

            $scope.setGraph = function(model) {
               $scope.myChartObject.data.rows = [];
               angular.forEach(model, function(dc) { // dc = DashboardColumn
                  var slice = {
                     c: [
                        { v: dc.name },
                        { v: dc.count }
                     ]
                  };
                  $scope.myChartObject.data.rows.push(slice);
               });
            };

            $scope.$watch('ngModel', function(model) {
               $scope.setGraph(model);
            });

         }]
      };
   });
