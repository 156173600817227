import addTemplate from './add.html';

angular.module('org.saga.widget.admin.add', [
   'org.saga.service'
])

   .directive('adminAddWidget', ['$log', 'ModelDesign', 'PropertiesLoader', 'ExampleProperties', 'Widget', '$filter', 'Utils', 'DownloadFile', function($log, ModelDesign, PropertiesLoader, ExampleProperties, Widget, $filter, Utils, DownloadFile) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: addTemplate,
         scope: {
            widget: '=',
            screen: '=',
            section: '=',
            instance: '='
         },
         controller: ['$scope', function($scope) {

            $scope.widgets = [];
            $scope.binding = {};
            $scope.defaultBindingMap = {};
            $scope.index = -1;

            if ($scope.widget.initType) {
               $scope.type = $scope.widget.initType;
            }
            $scope.index = $scope.widget.initIndex;

            Widget.query(function(widgetDefs) {
               angular.forEach(widgetDefs, function(widgetDef) {
                  $scope.widgets.push({ name: widgetDef.name, label: widgetDef.name, doc: widgetDef.documentation });
                  $scope.defaultBindingMap[widgetDef.name] = widgetDef.bindings;
               });
            });

            $scope.defaultBinding = function() {
               return $scope.defaultBindingMap[$scope.type];
            };

            $scope.cancel = function() {
               // TODO: fix this
               $scope.section.widgets.splice($scope.index, 1);
            };

            $scope.add = function() {
               // get example values
               var exampleProperties = ExampleProperties[$scope.type];
               exampleProperties = exampleProperties || {};

               // reduce empty bindings
               var b = Utils.cleanUp($scope.binding);
               var widget = {
                  ui: $scope.type + '-widget',
                  name: $scope.label.toLowerCase() + $filter('capitalize')($scope.type),
                  label: $scope.label,
                  binding: b,
                  properties: exampleProperties
               };
               ModelDesign.addWidget({
                  name: $scope.instance.name,
                  uuid: $scope.instance.uuid,
                  screen: $scope.screen.name,
                  section: $scope.section.name
               }, widget, function() {
                  $scope.section.widgets.splice($scope.index, 1);
                  $scope.section.widgets.splice($scope.index, 0, widget);
               });
            };

            $scope.selectedWidgetDoc = function() {
               if ($scope.type) {
                  for (var i in $scope.widgets) {
                     if ($scope.widgets[i].name === $scope.type) {
                        return $scope.widgets[i].doc;
                     }
                  }
               }
            };

            $scope.getDocumentation = (widgetName) => {
               DownloadFile.download(`/api/docs/widget/${widgetName}`, `${widgetName}.html`, 'widget.html', 'text/html');
            }

         }]
      };
   }]);
