import 'angular';
import moment from 'moment';
import 'angular-material';
import 'angular-route';
import 'angular-animate';
import 'angular-aria';
import 'angular-material-icons';
import 'angular-messages';
import 'angular-translate';
import 'angular-http-auth';
import 'angular-resource';
import 'angular-material-data-table';
import 'ng-material-datetimepicker';
import 'angular-sanitize';
import 'angular-resizable';
import translationsCz from './translations/cz.json';
import translationsEn from './translations/en.json';
import './images/favicon.ico';

//styles
import 'angular-material/angular-material.css';
import 'angular-material-icons/angular-material-icons.css';
import 'angular-material-data-table/dist/md-data-table.min.css';
import 'vis-network/dist/dist/vis-network.min.css';
import 'font-awesome/css/font-awesome.css';
import 'angular-resizable/angular-resizable.min.css';
import 'ng-material-datetimepicker/css/material-datetimepicker.css';
import './gfonts/gfonts.css';
import './app.css';

//components
import './clientClaim/clientClaim';
import './comp';
import './configuration/project';
import './dashboard/dashboard';
import './design';
import './diagram';
import './documentation';
import './vis/angular-vis';
import './newinstance/newinstance';
import './operations/operations';
import './queues/queues';
import './screen';
import './service';
import './taskinfo/taskinfo';
import './team/team';
import './users/users';
import './values';
import './widget';

import { routes } from './routes';

angular
   .module('org.saga', [
      'ngRoute',
      'ngMaterial',
      'ngResource',
      'ngAnimate',
      'ngMdIcons',
      'ngMessages',
      'ngVis',
      'md.data.table',
      'ngMaterialDatePicker',
      'pascalprecht.translate',
      'org.saga.adddocument',
      'org.saga.addidentity',
      'org.saga.bindhtmlcompile',
      'org.saga.clientClaim',
      'org.saga.companylist',
      'org.saga.configuration.project',
      'org.saga.dashboard',
      'org.saga.dashboardgraphs',
      'org.saga.design',
      'org.saga.design.schema',
      'org.saga.design.schema',
      'org.saga.design.schema',
      'org.saga.design.section',
      'org.saga.designhistory',
      'org.saga.diagram',
      'org.saga.documentation.widget',
      'org.saga.documentation.widgetlist',
      'org.saga.flow',
      'org.saga.flowmenu',
      'org.saga.mailboxconfig',
      'org.saga.modelupload',
      'org.saga.newinstance',
      'org.saga.operations',
      'org.saga.overview',
      'org.saga.personlist',
      'org.saga.preview',
      'org.saga.producttable',
      'org.saga.queues',
      'org.saga.screenheader',
      'org.saga.section',
      'org.saga.service',
      'org.saga.sidenav',
      'org.saga.task',
      'org.saga.taskinfo',
      'org.saga.team',
      'org.saga.toolbar',
      'org.saga.upddocstatus',
      'org.saga.users',
      'org.saga.widget',
      'org.saga.widgetlabel'
   ])

   .config(['$mdThemingProvider', function($mdThemingProvider) {
      var sagaBackground = $mdThemingProvider.extendPalette('light-blue', {
         50: '#ffffff',
         100: 'rgba(51, 102, 255, 0.12)',
         500: 'rgba(255,255,255,0.0)',
         900: '#212B36',
         A100: '#ffffff',
         A200: 'rgba(51,102,255,0.12)',
         A300: '#637381'
      });

      var sagaPrimary = $mdThemingProvider.extendPalette('blue', {
         300: '#919EAB',
         400: '#0093ee',
         500: '#3366FF',
         700: '#637381', //Light/Action/Active (secondary text, icons)
         800: '#3f536e',
         900: '#212B36', //primary text color
         A300: '#a8c6df'
      });

      var sagaAccent = $mdThemingProvider.extendPalette('light-green', {
         A200: '#00AB55',
         500: 'rgba(0, 171, 85, 0.12)'
      });

      $mdThemingProvider.definePalette('saga-background', sagaBackground);
      $mdThemingProvider.definePalette('saga-accent', sagaAccent);
      $mdThemingProvider.definePalette('saga-primary', sagaPrimary);

      $mdThemingProvider
         .theme('default')
         .backgroundPalette('saga-background')
         .primaryPalette('saga-primary')
         .accentPalette('saga-accent')
         .warnPalette('red');
   }])

   .config(['$routeProvider', '$httpProvider', '$translateProvider', function($routeProvider, $httpProvider, $translateProvider) {
      $translateProvider.translations('en', translationsEn);

      $translateProvider.translations('cs', translationsCz);

      $translateProvider
         .registerAvailableLanguageKeys(['en', 'cs'], {
            'en_*': 'en',
            'cs_*': 'cs',
            '*': 'en'
         })
         .determinePreferredLanguage();
      $translateProvider.useSanitizeValueStrategy(null);

      var lang = navigator.language || navigator.userLanguage;
      moment.locale(lang);
      window.InlineManual('update', { language: lang });

      if (lang === 'cs') {
         //       pickerProvider.setDaysNames([
         //          {'single':'N','shortName':'Ne','fullName':'Neděle'},
         //          {'single':'P','shortName':'Po','fullName':'Pondělí'},
         //          {'single':'Ú','shortName':'Út','fullName':'Úterý'},
         //          {'single':'S','shortName':'St','fullName':'Středa'},
         //          {'single':'Č','shortName':'Čt','fullName':'Čtvrtek'},
         //          {'single':'P','shortName':'Pá','fullName':'Pátek'},
         //          {'single':'S','shortName':'So','fullName':'Sobota'}
         //       ]);
      }

      $routeProvider
         .when('/dashboard', routes.dashboard)
         .when('/operations', routes.operations)
         .when('/operations/:query', routes.operationsQuery)
         .when('/task/:taskid', routes.task)
         .when('/task/:taskid/:mode', routes.taskMode)
         .when('/link/validate/:taskid/:token/', routes.taskClient)
         .when('/taskinfo/:taskid/:state', routes.taskinfo)
         .when('/diagram/:name/:uuid', routes.diagram)
         .when('/diagram/:name/:uuid/:taskName', routes.diagramTaskName)
         .when('/diagram/:name/:uuid/:screenName/:sectionName', routes.diagramSectionName)
         .when('/overview/:instanceId', routes.overview)
         .when('/newinstance', routes.newinstance)
         .when('/queues', routes.queues)
         .when('/queues/:role', routes.queuesRole)
         .when('/flow/:role/:interval/:shift', routes.flow)
         .when('/team', routes.team)
         .when('/team/:member', routes.teamMember)
         .when('/design', routes.design)
         .when('/design/history/:name', routes.designHistory)
         .when('/schema/:name/:uuid', routes.schema)
         .when('/documentation/widgetlist', routes.documentation)
         .when('/documentation/widget/:widgetName', routes.documentationWidget)
         .when('/configuration/project', routes.configuration)
         .when('/users', routes.users)
         .when('/loginSuccessful', routes.loginSuccessful)
         .otherwise({ redirectTo: function() {
               window.location = '/v2/tasks'
            } });

      $httpProvider.interceptors.push(['$q', '$rootScope', 'TokenStore', function($q, $rootScope, TokenStore) {
         return {
            request: function(config) {
               config.headers.Authorization = TokenStore.getToken();
               if ($rootScope.account) {
                  config.headers['X-User'] = $rootScope.account.name;
                  config.headers['X-Track-User-Id'] = $rootScope.account.name;
               }
               if ($rootScope.inTask && $rootScope.contextInfo) {
                  if ($rootScope.contextInfo.instanceId) {
                     config.headers['X-Instance-Id'] = $rootScope.contextInfo.instanceId;
                  }
                  if ($rootScope.contextInfo.taskId) {
                     config.headers['X-Task-Id'] = $rootScope.contextInfo.taskId;
                  }
                  if ($rootScope.contextInfo.caseId) {
                     config.headers['X-Track-Case-Id'] = $rootScope.contextInfo.caseId;
                  }
                  // NOTE: Use other information from contextInfo for additional headers
               }
               return config || $q.when(config);
            }
         };
      }]);
   }])

   .run(
      ['$rootScope',
         '$location',
         '$http',
         'AuthSharedService',
         'Session',
         function(
            $rootScope,
            $location,
            $http,
            AuthSharedService,
            Session
         ) {
            $rootScope.$on('$routeChangeStart', function(event, next) {
               if (next.originalPath === '/login' && $rootScope.authenticated) {
                  event.preventDefault();
               } else if (next.access && next.access.loginRequired && !$rootScope.authenticated) {
                  event.preventDefault();
                  sessionStorage.setItem('requestedUrl', location.hash);
                  $rootScope.$broadcast('event:auth-loginRequired', {});
               } else if (next.originalPath === '/loginSuccessful' && !$rootScope.authenticated) {
                  event.preventDefault();
                  AuthSharedService.loadAccount();
               } else if (
                  next.access &&
                  !AuthSharedService.isAuthorized(next.access.authorizedRoles)
               ) {
                  event.preventDefault();
                  $rootScope.$broadcast('event:auth-forbidden', {});
               } else if (next.access && next.access.loginRequired && $rootScope.authenticated) {
                  window.InlineManual('update', {});
               }
            });

            // Call when the client is confirmed
            $rootScope.$on('event:auth-loginConfirmed', function(event, data) {
               const requestedUrl = sessionStorage.getItem('requestedUrl');
               const defaultUrl = '/v2/tasks';
               let nextLocation = requestedUrl ?? defaultUrl;
               sessionStorage.removeItem('requestedUrl');
               Session.create(data);
               $rootScope.account = Session;
               $rootScope.authenticated = true;
               window.InlineManual('boot', {
                  'uid': data.id,
                  'email': data.email,
                  'username': data.name,
                  'name': data.displayName,
                  'roles': Session.userRoles
               });
               $rootScope.$evalAsync(function() {
                  location.href = nextLocation;
               });
            });

            // Call when the 401 response is returned by the server
            $rootScope.$on('event:auth-loginRequired', function(event, data) {
               Session.invalidate();
               $rootScope.authenticated = false;
               $rootScope.$evalAsync(function() {
                  window.location.replace('/login2');
               });
            });

            // Call when the 403 response is returned by the server
            $rootScope.$on('event:auth-forbidden', function(rejection) {
               $rootScope.$evalAsync(function() {
                  window.location.replace('/login2');
               });
            });

            // Call when the user logs out
            $rootScope.$on('event:auth-loginCancelled', function() {
               window.location.replace('/login2');
            });
         }]
   );
