import productTableTemplate from './producttable.html';

angular.module('org.saga.producttable', [
   'org.saga.service'
])

   .directive('productTable', ['ProductImages', 'EnumerationCache', function(ProductImages, EnumerationCache) {
      return {
         restrict: 'E',
         template: productTableTemplate,
         scope: {
            ngModel: '=',
            onSelect: '&',
            tableHeader: '=',
            multiSelect: '=',
            labels: '='
         },
         controller: ['$scope', function($scope) {

            $scope.promise = EnumerationCache.get('ProductType', function(types) {
               $scope.types = types;
            });

            $scope.selectProduct = function(product) {
               if ($scope.onSelect) {
                  $scope.onSelect({ product: product });
               }
            };

            $scope.getImage = function(product) {
               return ProductImages[product.type.name];
            };

         }]
      };
   }]);
