import googleMapTemplate from './googlemap.html';

angular.module('org.saga.googlemap', [
   'org.saga.service'
])

   .directive('googleMap', ['$log', 'GoogleMapAddressBuilder', function($log, GoogleMapAddressBuilder) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: googleMapTemplate,
         scope: {
            ngModel: '=',
            mapName: '=',
            zoom: '=',
            scrollwheel: '='
         },
         controller: ['$scope', function($scope) {

            function createMarker(pos, title) {
               if ($scope.marker) {
                  $scope.marker.setMap(null);
               }
               $scope.marker = new google.maps.Marker({
                  position: pos,
                  map: $scope.map,
                  title: title
               });
               $scope.map.setCenter(pos);
            }

            function setMarker(results, status) {
               if (status == google.maps.places.PlacesServiceStatus.OK) {
                  for (var i = 0; i < results.length; i++) {
                     var place = results[i];
                     $scope.ngModel.location.lat = place.geometry.location.lat();
                     $scope.ngModel.location.lng = place.geometry.location.lng();
                     createMarker($scope.ngModel.location, place.formatted_address);
                  }
               }
            };

            function findAddress() {
               var request = {
                  query: GoogleMapAddressBuilder.build($scope.ngModel.address),
                  fields: ['photos', 'formatted_address', 'name', 'geometry']
               };
               $log.info('address:' + request.query);
               $scope.service.findPlaceFromQuery(request, setMarker);
            }

            $scope.map = new google.maps.Map(document.getElementById('map'), {
               zoom: $scope.zoom,
               scrollwheel: $scope.scrollwheel,
               zoomControl: true
            });
            $scope.service = new google.maps.places.PlacesService($scope.map);

            if ($scope.ngModel.address) {
               findAddress();
            } else if ($scope.ngModel.location) {
               $log.info('location:' + $scope.ngModel.location);
               createMarker($scope.ngModel.location, $scope.ngModel.title);
            }

            $scope.$watch('ngModel.address', function(address) {
               console.log(address);
               findAddress();
            }, true);

         }]
      };
   }]);
