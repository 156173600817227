import previewTemplate from './preview.html';

angular.module('org.saga.preview', [
   'org.saga.service'
])

   .directive('documentPreview', ['$mdDialog', function($mdDialog) {
      return {
         restrict: 'E',
         template: previewTemplate,
         scope: {
            ngModel: '='
         },
         controller: ['$scope', '$sce', 'TokenStore', '$document', '$timeout', function($scope, $sce, TokenStore, $document, $timeout) {

            $scope.populateIframe = function(iframe, url, headers) {
               var xhr = new XMLHttpRequest();

               function handler() {
                  if (this.readyState === this.DONE) {
                     if (this.status === 200) {
                        // this.response is a Blob, because we set responseType above
                        iframe.src = URL.createObjectURL(this.response);
                     } else {
                        $mdDialog.show(
                           $mdDialog.alert()
                              .clickOutsideToClose(true)
                              .title('Chyba:')
                              .textContent('Soubor neexistuje')
                              .ok('OK')
                        );
                        console.error('XHR failed', this);
                     }
                  }
               }

               xhr.open('GET', url);
               xhr.onreadystatechange = handler;
               xhr.responseType = 'blob';
               headers.forEach(function(header) {
                  xhr.setRequestHeader(header[0], header[1]);
               });
               xhr.send();

            };


            $scope.getUrl = function() {
               var url = $sce.trustAsResourceUrl('api/document/' + $scope.ngModel.id + '/iframe');
               return url;
            };

            $scope.downloadFn = function() {
               var pdfiframe = window.document.querySelector('#pdfiframe');
               $scope.populateIframe(pdfiframe, $scope.getUrl(), [['Authorization', TokenStore.getToken()]]);
            };

            $timeout($scope.downloadFn, 100);

            $scope.getMimeType = function() {
               return $scope.ngModel.mimeType;
            };
         }]
      };
   }]);
