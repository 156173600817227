import filterTemplate from './filter.html';

angular.module('org.saga.widget.filter', ['org.saga.service']).directive('filterWidget', [
   'PropertiesLoader',
   'Utils',
   function (PropertiesLoader, Utils) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: filterTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            output: '=?'
         },
         controller: [
            '$scope',
            '$parse',
            'DefaultProperties',
            function ($scope, $parse, DefaultProperties) {
               PropertiesLoader.load($scope, DefaultProperties.filter);

               function getValueFromAttribute(attribute) {
                  if (typeof attribute === 'string') {
                     return $scope.instance.attributes[attribute];
                  } else {
                     return attribute;
                  }
               }

               $scope.filter = function () {
                  if (!Array.isArray($scope.localInput)) {
                     $scope.output = [];
                     return;
                  }

                  if (!$scope.expression || $scope.expression === '') {
                     $scope.output = $scope.localInput;
                     return;
                  }

                  $scope.output = $scope.localInput.reduce((acc, item) => {
                     const evalExpression = $parse($scope.expression)({
                        acc: acc,
                        item: item,
                        Date: Date,
                        ...$scope.instance.attributes
                     });
                     if (evalExpression) {
                        const returnValue = $parse($scope.outputItemExpression)({ item: item });
                        acc.push(returnValue);
                     }
                     return acc;
                  }, []);
               };

               $scope.setInputAndFilter = function () {
                  $scope.localInput = getValueFromAttribute($scope.input);
                  $scope.filter();
               }

               angular.forEach($scope.watching, function (w) {
                  $scope.$watch(
                     'instance.attributes.' + w,
                     Utils.lateWatch({ timeout: null }, 200, $scope.setInputAndFilter)
                  );
               });

               if (!$scope.watching || $scope.watching.length === 0) {
                  $scope.setInputAndFilter()
               }
            }
         ]
      };
   }
]);
