import addObjectWithRoleTemplate from './add-object-with-role.html';

angular.module('org.saga.addobjectwithrole', [
   'org.saga.service'
])

   .factory('AddObjectWithRoleDialog', ['$mdDialog', '$log', 'SearchRole', function($mdDialog, $log, SearchRole) {

      return {
         show: function(title, message, callback) {
            function DialogController($scope, $log, $mdDialog) {

               $scope.title = title;
               $scope.message = message;
               $scope.namePattern = '[a-zA-Z0-9]+';

               SearchRole.byType({ type: 'PROJECT' }, function(response) {
                  $scope.roles = response;
               });

               SearchRole.byType({ type: 'MANAGER' }, function(response) {
                  $scope.managerRoles = response;
               });

               $scope.newObject = { name: '', label: '', role: '', managerRole: '' };

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $log.info($scope.newObject);
                  $mdDialog.hide($scope.newObject);
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: addObjectWithRoleTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
