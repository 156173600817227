angular.module('org.saga.taskinfo', [
   'org.saga.service',
   'org.saga.toolbar'
])

   .controller('TaskInfoController', ['$scope', 'HumanTask', 'TaskUrl', '$location', '$routeParams', function($scope, HumanTask, TaskUrl, $location, $routeParams) {

      $scope.taskid = $routeParams.taskid;
      $scope.state = $routeParams.state;

      if ($scope.state !== 'TaskLoadError') {
         HumanTask.get({ id: $scope.taskid }, function(ht) {
            $scope.ht = ht;
         });
      }

   }]);

