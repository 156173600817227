angular.module('org.saga.widget', [
    'org.saga.widget.account',
    'org.saga.widget.admin.add',
    'org.saga.widget.button',
    'org.saga.widget.checkbox',
    'org.saga.widget.comments',
    'org.saga.widget.company',
    'org.saga.widget.date',
    'org.saga.widget.documentlist',
    'org.saga.widget.evaluator',
    'org.saga.widget.filter',
    'org.saga.widget.generatepdf',
    'org.saga.widget.history',
    'org.saga.widget.identity',
    'org.saga.widget.instancelist',
    'org.saga.widget.map',
    'org.saga.widget.modellist',
    'org.saga.widget.number',
    'org.saga.widget.person',
    'org.saga.widget.product',
    'org.saga.widget.productlist',
    'org.saga.widget.radio',
    'org.saga.widget.rest',
    'org.saga.widget.richtext',
    'org.saga.widget.robot',
    'org.saga.widget.select',
    'org.saga.widget.showhtml',
    'org.saga.widget.text',
    'org.saga.widget.timesheet',
]);
