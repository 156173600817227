angular.module('org.saga.newinstance', [
   'org.saga.service'
])

   .controller('NewInstanceController', ['$scope', '$log', 'Model', 'NewInstance', '$location',
      function($scope, $log, Model, NewInstance, $location) {

         $scope.tableHeader = 'Type of Instance';

         Model.manual(function(models) {
            $scope.models = models;
         });

         $scope.setModel = function(model) {
            NewInstance.run(model, function(url) {
               $location.path(url);
            });
         };

      }]);

