import jobDoneDialogTemplate from './jobdone-dialog.html';

angular.module('org.saga.dashboard', [
   'org.saga.service',
   'org.saga.toolbar',
   'org.saga.delegate',
   'org.saga.tasktable',
   'org.saga.taskcard',
   'org.saga.dashboardgraphs'
])

   .factory('JobDoneDialog', ['$mdDialog', function($mdDialog) {

      return {
         show: function() {
            function DialogController($scope, $log, $mdDialog) {

               $scope.ok = function() {
                  $mdDialog.hide();
               };

            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: jobDoneDialogTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  // callback is not called
               }, function() {
                  // callback is not called
               });

         }
      };
   }])

   .controller('DashboardController', ['$scope', 'AuthSharedService', 'Absent', 'JobDoneDialog', 'HumanTask', '$timeout', '$mdSidenav', '$mdDialog', '$log', '$location', '$rootScope', 'PriorityTask', 'TaskUrl', '$translate', '$interval',
      function($scope, AuthSharedService, Absent, JobDoneDialog, HumanTask, $timeout, $mdSidenav, $mdDialog, $log, $location, $rootScope, PriorityTask, TaskUrl, $translate, $interval) {

         let stopRefresh;
         $scope.graphsData = {};

         $scope.pieChartsVisible = false;

         $scope.tableOptions = { showOwner: false };

         $scope.absent = false;
         Absent.get({ user: $rootScope.account.name }, function(result) {
            $scope.absent = result.value;
         });

         $scope.isPowerUser = function() {
            return AuthSharedService.isAuthorized('POWER_USER');
         };

         const searchHumantask = function() {
            const options = { mine: true, team: $scope.isPowerUser() };
            HumanTask.search({}, options, function(list) {
               $scope.humantasks = list;
            });
         };

         $scope.refresh = function() {
            searchHumantask();

            if (angular.isDefined(stopRefresh)) {
               $interval.cancel(stopRefresh);
               stopRefresh = undefined;
            }

            stopRefresh = $interval(searchHumantask, 60000);
         };

         $scope.absentOff = function() {
            Absent.delete({ user: $rootScope.account.name }, function() {
               $scope.absent = false;
            });
         };

         $scope.priorityTask = function(ignoreMyTasks) {
            const bestHt = $scope.bestHt();
            const request = { current: bestHt, ignoreMyTasks: !!ignoreMyTasks };
            PriorityTask.fetch(request, function(response, headers, statusCode) {
               if (statusCode === 204) {
                  JobDoneDialog.show();
               } else if (response.showMyTaskWarning) {
                  const confirm = $mdDialog.confirm()
                     .title($translate.instant('TakeNewTaskTitle'))
                     .htmlContent($translate.instant('TakeNewTaskQuestion', bestHt))
                     .ok($translate.instant('Yes'))
                     .cancel($translate.instant('No'));

                  $mdDialog.show(confirm).then(function() {
                     $timeout($scope.priorityTask, 250, true, true);
                  });
               } else {
                  $scope.takeTask(response.result);
               }
            }, function(error) {
               $mdDialog.show(
                  $mdDialog.alert()
                     .clickOutsideToClose(true)
                     .title($translate.instant('TakeTaskFailed'))
                     .textContent(error.data)
                     .ok($translate.instant('Ok'))
               );
            });
         };

         $scope.takeTask = function(ht) {
            $location.path(TaskUrl.get(ht.id));
         };

         $scope.bestHt = function() {
            if ($scope.humantasks.length === 0) {
               return null;
            } else {
               return $scope.humantasks[0];
            }
         };

         $scope.pieCharts = function() {
            $scope.pieChartsVisible = !$scope.pieChartsVisible;
         };

         $scope.refresh();

         $scope.$on('$destroy', function() {
            $interval.cancel(stopRefresh);
         });

      }]);
