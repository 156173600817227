import instanceListTemplate from './instancelist.html';

angular.module('org.saga.instancelist', [
   'org.saga.service'
])

   .filter('mine', function() {
      return function(instances, option) {
         if (option) {
            var filtered = [];
            angular.forEach(instances, function(i) {
               if (i.createdBy) {
                  filtered.push(i);
               }
            });
            return filtered;
         }
         return instances;
      };
   })

   .directive('instanceList', function() {
      return {
         restrict: 'E',
         template: instanceListTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            humantasks: '=',
            promise: '=',
            displayOptions: '=',
            pageOptions: '=',
            onPageAction: '&'
         },
         link: function(scope, elem, attrs) {
            scope.name = attrs.name;
         },
         controller: ['$scope', '$location', 'Configuration', '$rootScope', 'TaskUrl', function($scope, $location, Configuration, $rootScope, TaskUrl) {

            $scope.options = {};
            $scope.options.maxItems = Configuration.listMaxItems;
            $scope.options.maxDescriptionLength = Configuration.maxDescriptionLength;

            $scope.account = $rootScope.account.name;

            $scope.checkUser = function(humantask) {
               return (humantask && humantask.owner && ($scope.account === humantask.owner.name));
            };

            $scope.first = function() {
               if ($scope.onPageAction) {
                  $scope.onPageAction({ action: 'FIRST' });
               }
            };

            $scope.last = function() {
               if ($scope.onPageAction) {
                  $scope.onPageAction({ action: 'LAST' });
               }
            };

            $scope.next = function() {
               if ($scope.onPageAction) {
                  $scope.onPageAction({ action: 'NEXT' });
               }
            };

            $scope.previous = function() {
               if ($scope.onPageAction) {
                  $scope.onPageAction({ action: 'PREVIOUS' });
               }
            };

            $scope.$watch('humantasks', function(humantasks) {
               if (!humantasks) {
                  return;
               }
               angular.forEach($scope.ngModel, function(instance) {
                  const list = $scope.humantasks[instance.instanceId];
                  if (list) {
                     instance.humantask = list;
                  }
               });
            });

            $scope.open = function(ht) {
               $location.path(TaskUrl.get(ht.id));
            };

            $scope.translateTaskStatusKey = {
               'READY': 'Ready',
               'RESERVED': 'Reserved',
               'IN_PROGRESS': 'InProgress'
            };
         }]
      };
   });

