import robotTemplate from './robot.html';

angular.module('org.saga.widget.robot', [
   'org.saga.service'
])

   .directive('robotWidget', ['PropertiesLoader', '$log', '$http', 'DefaultProperties', 'AuthSharedService', function(PropertiesLoader, $log, $http, DefaultProperties, AuthSharedService) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: robotTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            required: '='
         },

         link: function(scope, element, attrs, form) {
            scope.form = form;
         },

         controller: ['$scope', '$http', function($scope, $http) {
            PropertiesLoader.load($scope, DefaultProperties.robot);

            $scope.isDesigner = function() {
               return AuthSharedService.isAuthorized('DESIGNER');
            };

            $scope.getScriptName = function() {
               if ($scope.isDesigner()) {
                  return $scope.scriptName;
               } else {
                  return undefined;
               }
            };

            $scope.play = function() {
               $http({
                  url: $scope.url,
                  method: 'POST'
               });
            };
         }]
      };
   }]);
