import googleMapTemplate from './googlemap.html';

angular.module('org.saga.googlepanorama', [
   'org.saga.service'
])

   .directive('googlePanorama', ['$log', '$timeout', 'GoogleMapAddressBuilder', function($log, $timeout, GoogleMapAddressBuilder) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: googleMapTemplate,
         scope: {
            ngModel: '=',
            mapName: '=',
            zoom: '=',
            scrollwheel: '='
         },
         controller: ['$scope', function($scope) {

            function processSVData(data, status) {
               if (status === 'OK') {

                  var heading = google.maps.geometry.spherical.computeHeading(
                     data.location.latLng,
                     {
                        lat: function() {
                           return $scope.ngModel.location.lat;
                        }, lng: function() {
                           return $scope.ngModel.location.lng;
                        }
                     }
                  );

                  console.log('outside', { lat: data.location.latLng.lat(), lng: data.location.latLng.lng() });

                  var options = {
                     zoom: $scope.zoom,
                     scrollwheel: $scope.scrollwheel,
                     zoomControl: true,
                     pano: data.location.pano
                  };

                  $scope.map = new google.maps.StreetViewPanorama(document.getElementById('map'), options);
                  $scope.map.setPov({
                     heading: heading,
                     pitch: 10
                  });

                  $scope.marker = new google.maps.Marker({
                     position: $scope.ngModel.location,
                     map: $scope.map,
                     title: $scope.ngModel.title
                  });

               }
            }

            function createMarker(pos, title) {
               console.log('pos = ', pos);
               $scope.sv.getPanorama({ location: pos, radius: 50, source: google.maps.StreetViewSource.OUTDOOR },
                  processSVData);
            }

            function setMarker(results, status) {
               if (status == google.maps.places.PlacesServiceStatus.OK) {
                  for (var i = 0; i < results.length; i++) {
                     var place = results[i];
                     $scope.ngModel.location.lat = place.geometry.location.lat();
                     $scope.ngModel.location.lng = place.geometry.location.lng();
                     createMarker($scope.ngModel.location, place.formatted_address);
                  }
               }
            };

            function findAddress() {
               var request = {
                  query: GoogleMapAddressBuilder.build($scope.ngModel.address),
                  fields: ['photos', 'formatted_address', 'name', 'geometry']
               };
               $log.info('address:' + request.query);
               $scope.service.findPlaceFromQuery(request, setMarker);
            }

            $scope.sv = new google.maps.StreetViewService();
            $scope.service = new google.maps.places.PlacesService(document.getElementById('map'));

            if ($scope.ngModel.address) {
               findAddress();
            } else if ($scope.ngModel.location) {
               createMarker($scope.ngModel.location, $scope.ngModel.title);
            }

            $scope.$watch('ngModel.address', function(address) {
               console.log(address);
               findAddress();
            }, true);

         }]
      };
   }]);
