import addEdgeTemplate from './add-edge.html';

angular.module('org.saga.addedge', [
   'org.saga.service'
])

   .factory('AddEdgeDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(data, title, showStatus, callback) {
            function DialogController($scope, $log, $mdDialog, $filter) {

               $scope.showStatus = showStatus;
               $scope.title = title;
               $scope.data = data;
               $scope.message = 'New Edge: \'' + data.from + '\'->\'' + data.to + '\'';
               $scope.namePattern = '[a-zA-Z0-9]+';

               $scope.newEdge = {
                  name: data.from + 'To' + $filter('capitalize')(data.to),
                  expression: 'true',
                  status: undefined,
                  from: data.from,
                  to: data.to
               };

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  if (!$scope.newEdge.expression) {
                     $scope.newEdge.expression = true;
                  }
                  $log.info($scope.newEdge);
                  $mdDialog.hide($scope.newEdge);
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', '$filter', DialogController],
               template: addEdgeTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
