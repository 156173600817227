import restTemplate from './rest.html';

angular.module('org.saga.widget.rest', [
   'org.saga.service'
])

   .directive('restWidget', ['$rootScope', 'RestProxy', 'PropertiesLoader', function($rootScope, RestProxy, PropertiesLoader) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: restTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            output: '=?',
            onWidgetAction: '&'
         },
         controller: ['$scope', '$log', 'DefaultProperties', '$translate', 'Utils', 'InstanceExpression', function($scope, $log, DefaultProperties, $translate, Utils, InstanceExpression) {
            $scope.icons = { 'ok': 'done', 'error': 'bug_report', 'running': 'hourglass_empty', 'none': 'play_arrow' };
            let timeoutContext = { timeout: null };

            PropertiesLoader.load($scope, DefaultProperties.rest);

            const originalAttributes = angular.copy($scope.instance.attributes);

            if (!$scope.output) {
               $scope.output = {};
            }

            if (!$scope.output.status) {
               $scope.output.status = 'none';
            }

            const validateExpression = (expression) => {
               if (typeof expression === 'string') {
                  return InstanceExpression.eval($scope.hideButton, $scope.instance);
               } else {
                  return !!expression;
               }
            };

            $scope.isButtonHidden = () => {
               return validateExpression($scope.hideButton);
            };

            $scope.run = function() {
               $scope.output.status = 'running';
               const payload = {
                  urlTemplate: $scope.urlTemplate,
                  template: $scope.template,
                  instance: $scope.instance,
                  headers: $scope.headers,
                  local: $scope.local,
                  method: $scope.method
               };

               RestProxy.call({ instanceId: $scope.instance.instanceId }, payload, function(response) {
                  response = Utils.cleanUp(response);
                  angular.copy(response, $scope.output);
                  updateInstance();
                  closeSection();
               }, function() {
                  $scope.output.status = 'error';
                  $scope.output.response = null;
                  updateInstance();
                  closeSection();
               });

            };

            const closeSection = () => {
               if ($scope.closeSection) {
                  $scope.onWidgetAction({ widget: $scope.widget, action: 'SECTION_COMPLETE', data: {} });
               }
            };

            const updateInstance = () => {
               let instance = angular.copy($scope.instance);

               instance.attributes = Utils.getChangedAttributes($scope.instance.attributes, originalAttributes);

               instance.$update();
            };

            const runAutomatically = () => {
               const evalAutorun = validateExpression($scope.autorun);
               if (evalAutorun) {
                  $scope.run();
               }
            };

            runAutomatically();

            const runAndShowButton = () => {
               runAutomatically();
               $scope.isButtonHidden();
            };

            angular.forEach($scope.watching, function(w) {
               $scope.$watch('instance.attributes.' + w, Utils.lateWatch(timeoutContext, 200, runAndShowButton()));
            });
         }]
      };
   }]);
