import addMailboxConfigTemplate from './addmailboxconfig.html';

angular.module('org.saga.addmailboxconfig', [
   'org.saga.service'
])

   .factory('AddMailboxConfigDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(mailboxconfig, callback) {
            function DialogController($scope, $log, $mdDialog) {

               $scope.mailboxconfig = mailboxconfig;

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $mdDialog.hide($scope.mailboxconfig);
               };

            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: addMailboxConfigTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
