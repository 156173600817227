angular.module('org.saga.queues', [
   'org.saga.tasktable',
   'org.saga.service'
])

   .controller('QueuesController', ['$scope', '$log', '$mdDialog', 'FlowDialog', 'Queues', '$routeParams', 'Configuration', 'ActiveUsers', 'TaskOps', 'ActiveIdentities', 'IntervalFactory', 'HumanTaskFlow', '$location',
      function($scope, $log, $mdDialog, FlowDialog, Queues, $routeParams, Configuration, ActiveUsers, TaskOps, ActiveIdentities, IntervalFactory, HumanTaskFlow, $location) {

         $scope.showUsersImmediately = false;
         $scope.selectedRole = null;
         $scope.selectedRows = [];
         if ($routeParams.role) {
            $scope.selectedRole = { name: $routeParams.role };
            $scope.showUsersImmediately = true;
         }

         $scope.filter = { onlyEscalated: false, onlyOverdue: false };
         $scope.table = {};
         $scope.rows = {};
         $scope.flowData = { inflow: {}, outflow: {} };

         $scope.statuses = ['READY', 'RESERVED', 'IN_PROGRESS', 'SUSPENDED'];

         $scope.options = {};
         $scope.options.maxItems = Configuration.listMaxItems;
         $scope.options.maxLabelLength = Configuration.maxLabelLength;
         $scope.options.maxDescriptionLength = Configuration.maxDescriptionLength;

         $scope.humantasks = null;
         $scope.selected = [];
         $scope.filterMap = {};

         $scope.interval = { shift: 0, interval: 'month' };

         $scope.refresh = function() {
            $scope.promise = Queues.fetch({}, function(cells) {

               var map = {};
               angular.forEach(cells, function(cell) {
                  if (cell.role) {
                     var rowkey = cell.role.name;
                     map[rowkey] = { role: cell.role, key: rowkey };
                     var columns = $scope.table[rowkey];
                     if (!columns) {
                        columns = { 'READY': 0, 'RESERVED': 0, 'IN_PROGRESS': 0, 'SUSPENDED': 0 };
                        $scope.table[rowkey] = columns;
                        $scope.filterMap[rowkey] = {
                           'READY': false,
                           'RESERVED': false,
                           'IN_PROGRESS': false,
                           'SUSPENDED': false
                        };
                     }
                     columns[cell.status] = cell.count;
                  }
               });

               $scope.rows = [];
               for (var prop in map) {
                  if (map.hasOwnProperty(prop)) {
                     $scope.rows.push(map[prop]);
                  }
               }

               angular.forEach($scope.rows, function(row) {
                  ActiveIdentities.query({ name: row.role.name }, function(identities) {
                     row.role.allUsers = identities.length;
                     row.role.activeUsers = 0;
                     angular.forEach(identities, function(identity) {
                        if (identity.selected) {
                           row.role.activeUsers++;
                        }
                     });
                  });

                  if ($scope.showUsersImmediately && $scope.selectedRole && $scope.selectedRole.name === row.role.name) {
                     $scope.selectedRows.push(row);
                  }
               });

               if ($scope.selectedRows.length > 0) {
                  $scope.showActiveUsers($scope.selectedRows[0].role);
               }

            }).$promise;
         };

         $scope.refresh();

         $scope.getFlowRow = function(role, type) {
            var data = $scope.flowData[type];
            if (data.hasOwnProperty(role)) {
               var row = data[role];
               return row.total;
            }
            return 0;
         };

         $scope.graph = function(shift, interval) {
            $scope.interval.interval = interval;
            $scope.interval.shift = shift;
            var request = IntervalFactory.build(shift, interval);
            HumanTaskFlow.fetch({}, request, function(flowData) {
               $scope.flowData = flowData;
            });
         };

         $scope.$watch('ngModel', function() {
            $scope.graph(0, 'month');
         });

         $scope.intervalChanged = function(newInterval) {
            if (newInterval) {
               $scope.graph(newInterval.shift, newInterval.interval);
            }
         };

         $scope.filterByQuery = function(rows) {
            if (!$scope.options.query) {
               return rows;
            }
            var result = {};
            angular.forEach(rows, function(value, key) {
               if (value.role.label.indexOf($scope.options.query) >= 0) {
                  result[key] = value;
               }
            });
            return result;
         };

         $scope.cleanHumanTasks = function() {
            $scope.humantasks = undefined;
         };

         $scope.tableOptions = { showOwner: true };

         $scope.getTasks = function() {
            Queues.tasks($scope.filterMap, function(tasks) {
               $scope.humantasks = tasks;
            });
         };

         $scope.showActiveUsers = function(role) {
            $scope.selectedRole = role;
            ActiveIdentities.query({ name: role.name }, function(identities) {
               $scope.identities = identities;
            });
         };

         $scope.selectRow = function(row) {
            $scope.selectedRows.splice(0, $scope.selectedRows.length);
            $scope.selectedRows.push(row);
            $scope.showActiveUsers(row.role);
         };

         $scope.cancelActiveUsers = function() {
            $scope.identities = null;
         };

         $scope.showMember = function(member) {
            $location.path('/team/' + member.name);
         };

         $scope.showFlow = function(role) {
            FlowDialog.show(role, $scope.interval.interval, $scope.interval.shift);
         };

         $scope.updateActiveUsers = function() {
            var users = [];
            angular.forEach($scope.identities, function(identity) {
               if (identity.selected) {
                  users.push({ name: identity.name });
               }
            });
            ActiveUsers.update({ name: $scope.selectedRole.name }, users, function() {
               $scope.selectedRole = null;

               var alert = $mdDialog.alert()
                  .parent(angular.element(document.body))
                  .clickOutsideToClose(true)
                  .title('Active Users updated')
                  .textContent('Active Users was successfully updated for the selected role.')
                  .ariaLabel('Active Users')
                  .ok('OK');

               $mdDialog.show(alert).then(function() {
                  $scope.refresh();
               });
            });
         };

      }]);
