angular.module('org.saga.operations', [
   'org.saga.service',
   'org.saga.toolbar',
   'org.saga.instancelist',
   'org.saga.operationtable'
])

   .controller('OperationsController', ['$scope', 'Search', '$location', '$routeParams', 'Operation', function($scope, Search, $location, $routeParams, Operation) {

      $scope.query = $routeParams.query;

      $scope.totalPages = { my: 0, solved: 0, query: 0 };

      $scope.loadTasks = function(instances, propertyName) {
         let ids = [];
         angular.forEach(instances, function(instance) {
            ids.push({ instanceId: instance.instanceId });
         });
         Operation.search({}, ids, function(humantasks) {
            $scope[propertyName] = humantasks;
         });
      };

      $scope.loadMyInstances = function(page) {
         $scope.promise = Search.mine({ size: 10, page: page }, {}, function(result) {
            $scope.searchResults = result.content;
            $scope.totalPages.my = result.totalPages;
            $scope.loadTasks(result.content, 'humantasks');
         });
      };

      $scope.loadQueryInstances = function(page) {
         $scope.promise3 = Search.query({ query: $scope.query, size: 10, page: page }, {}, function(result) {
            $scope.queryResults = result.content;
            $scope.totalPages.query = result.totalPages;
            $scope.loadTasks(result.content, 'querytasks');
         });
      };

      $scope.loadSolvedInstances = function(page) {
         $scope.promise2 = Search.solved({ size: 10, page: page }, {}, function(result) {
            $scope.solvedResults = result.content;
            $scope.totalPages.solved = result.totalPages;
            $scope.loadTasks(result.content, 'solvedtasks');
         });
      };

      if ($scope.query) {
         $scope.loadQueryInstances(0);
      } else {
         $scope.loadMyInstances(0);
         $scope.loadSolvedInstances(0);
      }

      $scope.myAction = function(page) {
         $scope.loadMyInstances(page);
      };

      $scope.solvedAction = function(page) {
         $scope.loadSolvedInstances(page);
      };

      $scope.queryAction = function(page) {
         $scope.loadQueryInstances(page);
      };

      $scope.newInstance = function() {
         $location.path('/newinstance');
      };

   }]);

