import sidenavTemplate from './sidenav.html';

import '../../images/logoMPSV-m-sm.jpg';
import CustomIconTask from '../../icons/CustomIconTask.svg';
import CustomIconOperation from '../../icons/CustomIconOperation.svg';
import CustomIconAdd from '../../icons/CustomIconAdd.svg';
import CustomIconQueues from '../../icons/CustomIconQueues.svg';
import CustomIconTeam from '../../icons/CustomIconTeam.svg';
import CustomIconDesign from '../../icons/CustomIconDesign.svg';
import CustomIconWidget from '../../icons/CustomIconWidget.svg';
import CustomIconProject from '../../icons/CustomIconProject.svg';
import PersonFilled from '../../icons/PersonFilled.svg';
import LogoutFilled from '../../icons/LogoutFilled.svg';
import Insights from '../../icons/Insights.svg';

angular.module('org.saga.sidenav', [
   'org.saga.service'
])

   .directive('sidenav', ['AuthSharedService', '$location', 'NewInstance', '$log', 'Wizard', '$mdSidenav', '$rootScope',
      function(AuthSharedService, $location, NewInstance, $log, Wizard, $mdSidenav, $rootScope) {
         return {
            restrict: 'E',
            template: sidenavTemplate,
            transclude: true,
            controllerAs: 'ctrl',
            controller: ['$scope', function($scope) {

               $scope.path = $location.path();
               $scope.isUser = function() {
                  return AuthSharedService.isAuthorized('USER');
               };

               $scope.isManager = function() {
                  return AuthSharedService.isAuthorized('MANAGER');
               };

               $scope.isDesigner = function() {
                  return AuthSharedService.isAuthorized('DESIGNER');
               };

               $scope.isAdmin = function() {
                  return AuthSharedService.isAuthorized('ADMIN');
               };

               $scope.isClient = function() {
                  return AuthSharedService.isAuthorized('CLIENT');
               };

               var switchDesignMode = function() {
                  $rootScope.designModel.designMode = !$rootScope.designModel.designMode;
               };

               $scope.onPage = function(link) {
                  return $scope.path.indexOf(link) >= 0;
               };

               function runLink(link) {
                  $location.path(link);
               };

               function navigate(link) {
                  location.href = link;
               }

               $scope.menu = [
                  {
                     name: 'tasks',
                     label: 'Tasks',
                     action: () => navigate('/v2/tasks'),
                     link: '/dashboard',
                     icon: CustomIconTask,
                     visible: $scope.isUser() || $scope.isClient()
                  },
                  {
                     name: 'operations',
                     label: 'Operations',
                     action: () => navigate('/v2/operations'),
                     link: '/operations',
                     icon: CustomIconOperation,
                     visible: $scope.isUser()
                  },
                  {
                     name: 'newinstance',
                     label: 'NewInstance',
                     action: () => navigate('/v2/newInstance'),
                     link: '/newinstance',
                     icon: CustomIconAdd,
                     visible: $scope.isUser()
                  },
                  {
                     name: 'queues',
                     label: 'Queues',
                     action: () => runLink('/queues'),
                     link: '/queues',
                     icon: CustomIconQueues,
                     visible: $scope.isManager()
                  },
                  {
                     name: 'teams',
                     label: 'Team',
                     action: () => runLink('/team'),
                     link: '/team',
                     icon: CustomIconTeam,
                     visible: $scope.isManager()
                  },
                  {
                     name: 'models',
                     label: 'Design',
                     action: () => navigate('/v2/design'),
                     link: '/design',
                     icon: CustomIconDesign,
                     visible: $scope.isDesigner()
                  },
                  {
                     name: 'insights',
                     label: 'Insights',
                     action: () => navigate('/v2/insights'),
                     link: '/v2/insights',
                     icon: Insights,
                     visible: $scope.isManager()
                  },
                  {
                     name: 'library',
                     label: 'ApplianceLibrary',
                     action: () => navigate('/v2/library'),
                     link: '/v2/library',
                     icon: CustomIconOperation,
                     visible: $scope.isDesigner()
                  },
                  {
                     name: 'widgets',
                     label: 'Widgets',
                     action: () => runLink('/documentation/widgetlist'),
                     link: '/documentation/widgetlist',
                     icon: CustomIconWidget,
                     visible: $scope.isDesigner()
                  },
                  {
                     name: 'project',
                     label: 'Project',
                     action: () => runLink('/configuration/project'),
                     link: '/configuration/project',
                     icon: CustomIconProject,
                     visible: false
                  },
                  {
                     name: 'users',
                     label: 'Users',
                     action: () => navigate('/v2/users'),
                     link: '/users',
                     icon: PersonFilled,
                     visible: $scope.isAdmin()
                  }
               ];

               var logout = function() {
                  window.InlineManual('shutdown');
                  location.href = '/v2/logout';
               };

               $scope.logoutItem = {
                  name: 'logout',
                  label: 'Logout',
                  action: logout,
                  link: '/v2/logout',
                  icon: LogoutFilled,
                  visible: $scope.isUser()
               };

            }]
         };
      }]);
