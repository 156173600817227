import modelErrorDialogTemplate from './model-error-dialog.html';
import 'ng-file-upload';

angular.module('org.saga.modelupload', [
   'org.saga.service',
   'ngFileUpload'
])

   .factory('ModelErrorDialog', ['$mdDialog', function($mdDialog) {

      return {
         show: function(errors) {
            function DialogController($scope, $mdDialog) {

               $scope.errors = errors;

               $scope.ok = function() {
                  $mdDialog.hide();
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$mdDialog', DialogController],
               template: modelErrorDialogTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            });

         }
      };
   }])

   .factory('ModelUpload', ['Upload', '$mdDialog', 'ModelErrorDialog', function(Upload, $mdDialog, ModelErrorDialog) {
      var methods = {};

      methods.run = function(files, callback) {
         angular.forEach(files, function(file) {
            Upload.upload({
               url: 'api/model',
               file: file
            }).success(function(data) {
               callback(data);
            }).error(function(data, status) {
               if (status === 400) {
                  $mdDialog.show(
                     $mdDialog.alert()
                        .clickOutsideToClose(true)
                        .title('Invalid model')
                        .textContent(data)
                        .ok('Got it!')
                  );
               } else if (status === 409) { // model validation
                  ModelErrorDialog.show(data);
               }
            });
         });
      };

      return methods;
   }]);
