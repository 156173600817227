import accountTemplate from './account.html';

angular.module('org.saga.widget.account', [
   'org.saga.service'
])

   .directive('accountWidget', ['PropertiesLoader', 'EnumerationCache', 'ListUtils', '$log', '$filter', function(PropertiesLoader, EnumerationCache, ListUtils, $log, $filter) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: accountTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            required: '=',
            account: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.account);

            $scope.autoHideWarnings = !($scope.showValidateWarnings === 'force');

            if ($scope.bankCode) {
               if (!$scope.account) {
                  $scope.account = {};
               }
               $scope.account.bankCode = $scope.bankCode;
            }

            EnumerationCache.get('BankCodeType', function(bankCodes) {
               $scope.bankCodes = ListUtils.fromMap(bankCodes);
               if ($scope.account && $scope.account.bankCode) {
                  $scope.selectedBankCode = bankCodes[$scope.account.bankCode];
               }
            });

            $scope.selectBankCode = function(bc) {
               if (!$scope.account) {
                  $scope.account = {};
               }
               if (bc) {
                  $scope.account.bankCode = bc.name;
               } else {
                  $scope.account.bankCode = null;
               }
            };

            $scope.labelBankCode = function(bc) {
               return bc.name + ' ' + bc.label;
            };

            $scope.queryBankCodes = function(text) {
               return $filter('filter')($scope.bankCodes, function(value) {
                  var s = $scope.labelBankCode(value).toUpperCase();
                  return s.indexOf(text.toUpperCase()) >= 0;
               });
            };

         }]
      };
   }]);
