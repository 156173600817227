import productTemplate from './product.html';

angular.module('org.saga.widget.product', [
   'org.saga.service'
])

   .directive('productWidget', ['PropertiesLoader', 'EnumerationCache', 'ClientProduct', 'Product', 'Account', '$log', 'ListUtils', 'Utils', 'DefaultProperties', 'ProductImages',
      function(PropertiesLoader, EnumerationCache, ClientProduct, Product, Account, $log, ListUtils, Utils, DefaultProperties, ProductImages) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: productTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               required: '=',
               client: '=?',
               product: '=?',
               account: '=?'
            },
            link: function(scope, element, attrs, form) {
               scope.form = form;
            },
            controller: ['$scope', function($scope) {

               PropertiesLoader.load($scope, DefaultProperties.product);
               $scope.searchBox = {};
               $scope.searching = false;

               var timeoutContext = { timeout: null };

               EnumerationCache.get('ProductType', function(types) {
                  $scope.types = types;
               });

               $scope.getImage = function(product) {
                  if (!product) {
                     return ProductImages['X'];
                  }
                  return ProductImages[product.type.name];
               };

               $scope.reload = function() {
                  if ($scope.product && $scope.product.id) {
                     Product.get({ id: $scope.product.id }, function(product) {
                        $scope.product = product;
                     });
                  }
               };

               $scope.$watch('client', function(newClient, oldClient) {
                  if (newClient && oldClient && newClient.id == oldClient.id) {
                     return;
                  }
                  $scope.product = null;
               });

               $scope.clearProduct = function() {
                  $scope.product = null;
               };

               $scope.searchProducts = function() {
                  $scope.searching = true;
                  if ($scope.client && $scope.client.id) {
                     ClientProduct.query({ clientId: $scope.client.id }, function(products) {
                        $scope.products = ListUtils.filter(products, $scope.filter, function(item) {
                           return InstanceExpression.eval($scope.filter, $scope.instance, item); // jshint ignore:line
                        });
                     });
                  } else {
                     Product.search($scope.searchBox, {}, function(products) {
                        $scope.products = ListUtils.filter(products, $scope.filter, function(item) {
                           return InstanceExpression.eval($scope.filter, $scope.instance, item); // jshint ignore:line
                        });
                     });
                  }
               };

               $scope.findByAccount = function() {
                  if (!$scope.account || $scope.product) {
                     return;
                  }
                  Account.search({}, $scope.account, function(product) {
                     $scope.product = product;
                  }, function(error) {
                     // do nothing
                  });
               };

               $scope.$watch('account', Utils.lateWatch(timeoutContext, 200, $scope.findByAccount), true);

               $scope.selectProduct = function(product) {
                  $scope.product = product;
                  $scope.searching = false;
               };

               $scope.isValid = function() {
                  if ($scope.required && (!$scope.product || !$scope.product.id)) {
                     return false;
                  }
                  return true;
               };

               $scope.reload();

            }]
         };
      }]);
