import dashboardGraphsTemplate from './dashboard-graphs.html'

angular.module('org.saga.dashboardgraphs', [
	'org.saga.service',
	'org.saga.taskspie',
	'org.saga.gauge',
	'org.saga.taskhistogram'
])

	.directive('dashboardGraphs', ['Dashboard', 'IntervalFactory', function(Dashboard, IntervalFactory) {
		return {
			restrict: 'E',
			template: dashboardGraphsTemplate,
			scope: {
				ngModel: '=',
			},
			controller: ['$scope', function($scope) {

				$scope.interval = {shift: 0, interval:'month'};

				$scope.graph = function(shift, interval) {
					var dashboardRequest = IntervalFactory.build(shift, interval);
					if ($scope.ngModel.user) {
						dashboardRequest.user = {name: $scope.ngModel.user};
					}
					Dashboard.data({}, dashboardRequest, function(dashboardData) {
						$scope.dashboardData = dashboardData;
					});
				};

				$scope.$watch('ngModel', function() {
					$scope.graph(0, 'month');
				});

				$scope.intervalChanged = function(newInterval) {
					if (newInterval) {
						$scope.graph(newInterval.shift, newInterval.interval);
					}
				};

			}]
		};
	}]);
