import buttonTemplate from './button.html';

angular.module('org.saga.widget.button', [
   'org.saga.service'
])

   .directive('buttonWidget', ['$rootScope', 'InstanceExpression', 'PropertiesLoader', function($rootScope, InstanceExpression, PropertiesLoader) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: buttonTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            value: '=?',
            onWidgetAction: '&'
         },
         controller: ['$scope', '$log', '$rootScope', 'DefaultProperties', function($scope, $log, $rootScope, DefaultProperties) {

            $scope.pressed = undefined;
            if (!$scope.value) {
               $scope.value = false;
            }

            PropertiesLoader.load($scope, DefaultProperties.button);

            $scope.toggle = function() {
               $scope.value = !$scope.value;
               if ($scope.value && $scope.closeSection) {
                  $scope.onWidgetAction({ widget: $scope.widget, action: 'SECTION_COMPLETE', data: {} });
               }
            };

            $scope.urlFunction = function() {
               return InstanceExpression.eval($scope.url, $scope.instance); // jshint ignore:line
            };

            $scope.$watch('value', function() {
               $scope.pressed = ($scope.value) ? true : undefined;
            });

         }]
      };
   }]);
