import 'ng-file-upload';

angular.module('org.saga.design', [
   'org.saga.service',
   'ngFileUpload',
   'org.saga.modelupload',
   'org.saga.addedge'
])

   .controller('DesignController', ['$scope', '$sce', '$http', 'Model', 'NewInstance', 'Upload', '$location', '$mdDialog', '$log', '$translate', 'ModelUpload', 'AddObjectDialog', 'DownloadFile',
      function($scope, $sce, $http, Model, NewInstance, Upload, $location, $mdDialog, $log, $translate, ModelUpload, AddObjectDialog, DownloadFile) {

         $scope.options = {};

         $scope.refresh = function() {
            $scope.models = Model.query();
         };

         $scope.loadModel = function(files) {
            ModelUpload.run(files, function() {
               $scope.refresh();
            });
         };

         $scope.createInstance = function(model) {
            NewInstance.run(model, function(url) {
               $location.path(url);
            });
         };

         $scope.deleteModel = function(model) {
            var confirm = $mdDialog.confirm()
               .title($translate.instant('DeleteModelTitle'))
               .textContent($translate.instant('DeleteModelConfirm'))
               .ariaLabel('deleteModel')
               .ok($translate.instant('Ok'))
               .cancel($translate.instant('Cancel'));

            $mdDialog.show(confirm).then(function() {
               model.$remove(function() {
                  $scope.refresh();
               });
            }, function() {
               // do nothing
            });
         };

         $scope.downloadModel = function(model) {
            let fileName = `${model.name}.json`;
            DownloadFile.download(`/api/model/${ model.name}/${model.uuid}/data`, fileName, 'model.json', 'application/json');
         };

         $scope.buildModel = function() {
            AddObjectDialog.show('BuildModel', 'BuildModelMessage', function(obj) {
               Model.build(obj, function() {
                  var dlg = $mdDialog.alert()
                     .clickOutsideToClose(true)
                     .title($translate.instant('ModelCreatedTitle'))
                     .textContent($translate.instant('ModelCreatedSuccessfully'))
                     .ariaLabel('Model Created')
                     .ok($translate.instant('Ok'));

                  $mdDialog.show(dlg).then(function() {
                     $scope.refresh();
                  });
               });
            });
         };

         $scope.clearFilter = function() {
            $scope.options.query = undefined;
            document.getElementById('search-box').focus();
         };

         $scope.refresh();
      }]);

