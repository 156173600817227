import screenHeaderTemplate from './screenheader.html';

angular.module('org.saga.screenheader', [
   'org.saga.service'
])

   .directive('screenHeader', ['Template', 'Utils', '$log',
      function(Template, Utils, $log) {
         return {
            restrict: 'E',
            template: screenHeaderTemplate,
            scope: {
               instance: '=',
               header: '='
            },
            controller: ['$scope', function($scope) {

               var timeoutContext = { timeout: null };

               $scope.generate = function() {
                  var t = new Template();
                  t.instance = $scope.instance;
                  t.template = $scope.header.template;
                  t.$save(function(result) {
                     $scope.htmltext = result.response;
                  }, function(error) {
                     $log.error(error.data);
                  });
               };

               angular.forEach($scope.header.watching, function(w) {
                  $scope.$watch('instance.attributes.' + w,
                     Utils.lateWatch(timeoutContext, 200, $scope.generate));
               });

            }]
         };
      }]);
