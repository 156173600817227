import instanceListTemplate from './instancelist.html';

angular.module('org.saga.widget.instancelist', [
   'org.saga.service',
   'org.saga.instancelist'
])

   .directive('instancelistWidget', ['PropertiesLoader', '$log', 'InstanceExpression', 'Search', 'Utils', function(PropertiesLoader, $log, InstanceExpression, Search, Utils) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: instanceListTemplate,
         scope: {
            widget: '=',
            editable: '=',
            required: '=',
            instance: '=',
            instances: '=?'
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.instancelist);

            var timeoutContext = { timeout: null };

            $scope.displayOptions = { tasks: false, filter: $scope.showFilter };

            $scope.search = function() {
               var q = InstanceExpression.eval($scope.query, $scope.instance); // jshint ignore:line
               if (q != null) {
                  $scope.promise = Search.query({ query: q }, function(result) {
                     var list = result.content;
                     if (!$scope.displayCurrent) {
                        list = list.filter(function(i) {
                           return i.instanceId != $scope.instance.instanceId;
                        });
                     }
                     $scope.instances = list;
                  });
               } else {
                  $scope.instances = [];
               }
            };

            angular.forEach($scope.watching, function(w) {
               $scope.$watch('instance.attributes.' + w,
                  Utils.lateWatch(timeoutContext, 200, $scope.search));
            });

            if (!$scope.watching || $scope.watching.length == 0) {
               $scope.search();
            }

         }]
      };
   }]);
