import mapTemplate from './map.html';

angular.module('org.saga.widget.map', [
   'org.saga.service',
   'org.saga.googlemap',
   'org.saga.googlepanorama'
])

   .directive('mapWidget', ['PropertiesLoader', function(PropertiesLoader) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: mapTemplate,
         scope: {
            widget: '=',
            editable: '=',
            required: '=',
            location: '=?',
            title: '=?',
            address: '=?'
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {
            PropertiesLoader.load($scope, DefaultProperties.map);

            if (!$scope.location) {
               $scope.location = {};
            }

            $scope.data = { location: $scope.location, address: $scope.address, title: $scope.title };

            $scope.getMapName = function() {
               return $scope.widget.name + '-map';
            };

         }]
      };
   }]);
