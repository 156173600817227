import suspendTemplate from './suspend.html';

angular.module('org.saga.suspend', [
   'org.saga.service',
   'ngMaterialDatePicker'
])

   .factory('SuspendDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(callback) {
            function DialogController($scope, $log, $mdDialog, mdcDefaultParams, $translate) {
               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               var lang = navigator.language || navigator.userLanguage;
               mdcDefaultParams({
                  lang: lang,
                  cancelText: $translate.instant('Cancel'),
                  okText: $translate.instant('Ok')
               });

               $scope.minDate = new Date();

               $scope.ok = function() {
                  $log.info($scope.suspendedUntil);
                  $mdDialog.hide($scope.suspendedUntil);
               };
            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', 'mdcDefaultParams', '$translate', DialogController],
               template: suspendTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
