import taskTableTemplate from './task-table.html';

angular.module('org.saga.tasktable', [
   'org.saga.service',
   'org.saga.taskcard'
])

   .filter('urged', function() {
      return function(tasks, option) {
         if (option) {
            let filtered = [];
            angular.forEach(tasks, function(task) {
               if (task.urged) {
                  filtered.push(task);
               }
            });
            return filtered;
         }
         return tasks;
      };
   })

   .filter('escalated', function() {
      return function(tasks, option) {
         if (option) {
            let filtered = [];
            angular.forEach(tasks, function(task) {
               if (task.escalatedAt) {
                  filtered.push(task);
               }
            });
            return filtered;
         }
         return tasks;
      };
   })

   .filter('mineteam', function() {
      return function(tasks, option) {
         let filtered = [];
         angular.forEach(tasks, function(task) {
            if (option.ignoreOwner) {
               filtered.push(task);
            } else if (option.mine && task.owner && task.owner.name === option.account) {
               filtered.push(task);
            } else if (option.team && (!task.owner || task.owner.name !== option.account)) {
               filtered.push(task);
            }
         });
         return filtered;
      };
   })

   .filter('suspended', function() {
      return function(tasks, option) {
         let filtered = [];
         angular.forEach(tasks, function(task) {
            if (option && task.status === 'SUSPENDED') {
               filtered.push(task);
            } else if (!option && task.status !== 'SUSPENDED') {
               filtered.push(task);
            }
         });
         return filtered;
      };
   })

   .directive('taskTable', ['$log', 'Configuration', 'AuthSharedService', function($log, Configuration, AuthSharedService) {
      return {
         restrict: 'E',
         template: taskTableTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            refreshFn: '&',
            showTeamButton: '=',
            ignoreOwner: '=',
            openDisabled: '=',
            selectionEnabled: '=',
            tableOptions: '='
         },
         controller: ['$scope', function($scope) {

            $scope.account = AuthSharedService.getAccount();

            $scope.options = {};
            $scope.options.maxItems = Configuration.listMaxItems;
            $scope.options.maxLabelLength = Configuration.maxLabelLength;
            $scope.options.maxDescriptionLength = Configuration.maxDescriptionLength;
            $scope.options.mine = true;
            $scope.options.team = $scope.showTeamButton;
            $scope.options.ignoreOwner = $scope.ignoreOwner;
            $scope.options.account = $scope.account.name;
            $scope.options.openDisabled = $scope.openDisabled;
            $scope.options.selectionEnabled = $scope.selectionEnabled;

            $scope.clearFilter = function() {
               $scope.options.query = undefined;
               document.getElementById('search-box').focus();
            };

            $scope.toggleFilterOption = function(filterOption) {
               $scope.options[filterOption] = !$scope.options[filterOption];
            };

            $scope.refresh = function() {
               $scope.refreshFn();
            };
         }]
      };
   }]);
