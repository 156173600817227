angular.module('org.saga.task', [
   'org.saga.service',
   'org.saga.toolbar',
   'org.saga.suspend',
   'org.saga.commands',
   'org.saga.addobject',
   'org.saga.addobjectwithrole',
   'org.saga.taskdesign'
])

   .controller('TaskController', ['$scope', '$log', '$location', '$routeParams', 'AuthSharedService', 'Instance',
      'HumanTask', 'SuspendDialog', 'HumanTaskOperation', 'Task', '$timeout', '$rootScope', 'InstanceExpression', '$mdSidenav',
      'Edge', 'ErrorToast', 'Work', 'Utils',
      function($scope, $log, $location, $routeParams, AuthSharedService, Instance,
               HumanTask, SuspendDialog, HumanTaskOperation, Task, $timeout, $rootScope, InstanceExpression, $mdSidenav,
               Edge, ErrorToast, Work, Utils) {

         $scope.taskid = $routeParams.taskid;
         $scope.mode = $routeParams.mode;
         $scope.isClientMode = $scope.mode === 'client';
         $scope.addObjectOpen = false;

         $scope.isDesigner = function() {
            return AuthSharedService.isAuthorized('DESIGNER');
         };

         $rootScope.contextInfo = {};
         $scope.humantask = null;
         $rootScope.designModel = { designMode: $scope.isDesigner(), showDebug: false };

         $scope.evaluateExpression = InstanceExpression.get;

         let hto = new HumanTaskOperation();
         hto.id = $scope.taskid;
         hto.operation = 'START';

         $scope.toggleDesignMode = function() {
            $rootScope.designModel.designMode = !$rootScope.designModel.designMode;
            $mdSidenav('left-side-nav').toggle();
         };

         const handleCloseWindow = function(state) {
            if ($scope.mode === 'internal') {
               location.href = '/v2/tasks';
            } else {
               $location.path('/taskinfo/' + $scope.taskid + '/' + state);
            }
         };

         let originalAttributes = {};

         hto.$update(function(ht) {
            $log.info('human task ' + hto.id + ' taken');
            $scope.ht = ht;
            $scope.humantask = ht;
            $scope.instanceId = ht.instance.instanceId;

            $rootScope.contextInfo = { taskId: $scope.taskid };

            Instance.get({ instanceId: $scope.instanceId }, function(instance) {
               $log.info('instance loaded ' + $scope.instanceId);
               originalAttributes = angular.copy(instance.attributes);
               $scope.instance = instance;

               // NOTE: Add any data from the instance to contextInfo
               if (typeof $scope.instance.attributes.loanRequest !== 'undefined' && typeof $scope.instance.attributes.loanRequest.id !== 'undefined') {
                  $rootScope.contextInfo = {
                     instanceId: $scope.instanceId,
                     taskId: $scope.taskid,
                     caseId: $scope.instance.attributes.loanRequest.id
                  };
               } else {
                  $rootScope.contextInfo = { instanceId: $scope.instanceId, taskId: $scope.taskid };
               }


               Task.get({
                  name: $scope.instance.name,
                  uuid: $scope.instance.uuid,
                  taskName: $scope.ht.name
               }, function(task) {
                  $log.info('design task ' + $scope.ht.name + ' loaded');
                  $scope.screen = task;
                  $scope.screenLabel = $scope.screen.label + ', ' + $scope.instance.description;
                  Edge.init($scope.instance, $scope.screen, $scope.ht);
               }, function() {
                  $scope.showError('TaskDesignError');
               });
            }, function() {
               $scope.showError('LoadInstanceError');
            });
         }, function() {
            HumanTask.get({ id: $scope.taskid }, function(ht) {
               if (ht.status === 'COMPLETED') {
                  handleCloseWindow('TaskCompleted');
               } else {
                  $scope.showError('TakeClaimError');
               }
            }, function() {
               $scope.showError('TakeClaimError');
            });
         });

         $scope.showError = function(message) {
            ErrorToast.show(message, function() {
               handleCloseWindow('TaskLoadError');
            });
         };

         $scope.isSectionVisible = (section) => Edge.isVisible(section);

         $scope.commandFunction = function(command) {
            $log.info('command = ' + command.type);
            const fn = $scope.possibleCommands[command.type];
            fn(command);
         };

         const getChangedInstanceAttrs = () => Utils.getChangedAttributes($scope.instance.attributes, originalAttributes);

         $scope.save = function() {
            $scope.instance.attributes = getChangedInstanceAttrs();
            $scope.instance.$update(() => {
               let hto = new HumanTaskOperation();
               hto.id = $scope.taskid;
               hto.operation = 'STOP';
               hto.$update(function() {
                  $log.info('task saved');
                  handleCloseWindow('TaskSaved');
               }, function() {
                  $scope.showError('SaveInstanceError');
               });
            });
         };

         $scope.internalComplete = function(callback) {
            const nominalWork = Work.nominalWork($scope.instance, $scope.screen);
            $scope.instance.attributes = getChangedInstanceAttrs();
            $scope.instance.snapshots = [];
            $scope.instance.$update((instance) => {
               let hto = new HumanTaskOperation();
               hto.id = $scope.taskid;
               hto.operation = 'COMPLETE';
               hto.nominalWork = nominalWork;
               hto.$update(function() {
                  callback(instance);
               }, function() {
                  $scope.showError('TaskCompleteError');
               });
            }, function() {
               $scope.showError('SaveInstanceError');
            });
         };

         $scope.suspend = function(command) {
            $scope.instance.attributes = getChangedInstanceAttrs();
            $scope.instance.$update(function() {
               let hto = new HumanTaskOperation();
               hto.id = $scope.taskid;
               hto.suspension = command.suspension;
               hto.operation = 'SUSPEND';
               hto.$update(function() {
                  $log.info('task suspended');
                  handleCloseWindow('TaskSaved');
               });
            });
         };

         $scope.exit = function() {
            let hto = new HumanTaskOperation();
            hto.id = $scope.taskid;
            hto.operation = 'EXIT';
            hto.$update(function() {
               $log.info('task exited');
               handleCloseWindow('TaskSaved');
            });
         };

         $scope.complete = function() {
            $scope.internalComplete(function() {
               $log.info('task completed');
               handleCloseWindow('TaskCompleted');
            });
         };

         $scope.close = function() {
            $log.info('task closed');
            $location.path('/dashboard');
         };

         $scope.isFormInvalid = () => $scope.screenForm.$invalid;

         $scope.possibleCommands = {
            'SAVE': $scope.save,
            'COMPLETE': $scope.complete,
            'EXIT': $scope.exit,
            'RELEASE': $scope.release,
            'CLOSE': $scope.close,
            'SUSPEND': $scope.suspend
         };
      }]);

