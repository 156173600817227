import evaluatorTemplate from './evaluator.html'

angular.module('org.saga.widget.evaluator', [
   'org.saga.service'
])

   .directive('evaluatorWidget', ['InstanceExpression', 'PropertiesLoader', 'Utils', function(InstanceExpression, PropertiesLoader, Utils) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: evaluatorTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            output: '=?'
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.evaluator);
            const timeoutContext = { timeout: null };

            $scope.evaluate = function () {
               $scope.output = InstanceExpression.eval($scope.expression, $scope.instance); // jshint ignore:line
            };

            angular.forEach($scope.watching, function(w) {
               $scope.$watch('instance.attributes.' + w, Utils.lateWatch(timeoutContext, 200, $scope.evaluate));
            });

            if (!$scope.watching || $scope.watching.length === 0) {
               $scope.evaluate();
            }

         }]
      };
   }]);
