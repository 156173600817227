import mailboxConfigTemplate from './mailboxconfig.html';

angular.module('org.saga.mailboxconfig', [
   'org.saga.service',
   'org.saga.addmailboxconfig'
])

   .directive('mailboxConfig', ['$log', '$translate', '$mdDialog', 'Mailbox', 'MailboxConfig', function($log, $translate, $mdDialog, Mailbox, MailboxConfig) {

      return {
         restrict: 'E',
         template: mailboxConfigTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '='
         },
         controller: ['$scope', function($scope) {
            $scope.remove = function() {
               var confirm = $mdDialog.confirm()
                  .title($translate.instant('Delete'))
                  .textContent($translate.instant('DoYouWantToDelete?'))
                  .ariaLabel('Delete')
                  .ok($translate.instant('Ok'))
                  .cancel($translate.instant('Cancel'));

               $mdDialog.show(confirm).then(function() {
                  MailboxConfig.remove({ id: $scope.ngModel.id }, function() {
                     // this is ok
                  });
               });
            };
         }]
      };
   }]);
