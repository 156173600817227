angular.module('org.saga.documentation.widgetlist', [
   'org.saga.service'
])

   .controller('WidgetListController', ['$scope', 'Widget', 'WidgetIcons', '$sce', '$http', '$mdDialog', 'DownloadFile', function($scope, Widget, WidgetIcons, $sce, $http, $mdDialog, DownloadFile) {
      $scope.widgets = Widget.query();

      $scope.widgetIcon = function(w) {
         return WidgetIcons[w.name];
      };

      $scope.downloadWidgetDocumentation = function(widgetName) {
         DownloadFile.download(`/api/docs/widget/${widgetName}`, `${widgetName}.html`, 'widget.html', 'text/html');
      };
   }]);

