import widgetLabelTemplate from './widgetlabel.html';

angular.module('org.saga.widgetlabel', [
   'org.saga.service'
])

   .directive('widgetLabel', ['$log', function($log) {
      return {
         restrict: 'E',
         template: widgetLabelTemplate,
         scope: {
            ngModel: '='
         },
         controller: ['$scope', function($scope) {
            $log.debug('label = ' + $scope.ngModel.label);
         }]
      };
   }]);
