import { DataSet } from 'vis-timeline/standalone';

angular.module('org.saga.diagram', [
	'org.saga.service',
	'angularResizable',
	'org.saga.jsontext',
	'org.saga.diagramnav',
	'ngVis'
])

.controller('DiagramController', ['$scope', '$log', 'Model', '$filter', '$translate', 'AddEdgeDialog', 'ModelDesign', '$routeParams', '$timeout', '$mdDialog', '$location', 'AddObjectDialog', 'AddObjectWithRoleDialog', function($scope, $log, Model, $filter, $translate, AddEdgeDialog, ModelDesign, $routeParams, $timeout, $mdDialog, $location, AddObjectDialog, AddObjectWithRoleDialog) {

	$scope.name = $routeParams.name;
	$scope.uuid = $routeParams.uuid;

	$scope.networkObject = {};

	function getNodeId(node) {
		return (node.type == 'TASK' || node.type == 'SECTION') ? node.name : node.type.toLowerCase();
	}

	function findNodeByName(nodes, nodeName) {
	    var selected;
		angular.forEach(nodes, function(node) {
			if (node.name === nodeName) {
			    selected = node;
			    return false;
			}
		});

		return selected;
	}

    function findDataNodeById(nodeId) {
	    var foundNode;
		angular.forEach($scope.data.nodes, function(node) {
			if (node.id === nodeId) {
			    foundNode = node;
			    return false;
			}
		});

		return foundNode;
    }

    function findDataEdgeById(edgeId) {
	    var foundEdge;
		angular.forEach($scope.data.edges, function(edge) {
			if (edge.id === edgeId) {
				foundEdge = edge;
			    return false;
			}
		});

		return foundEdge;
    }

    function getX(positions, nodeId, nodeType) {
    	if (nodeType) {
    		var foundX;
    		angular.forEach(positions, function(pos) {
    			if (pos.type === nodeType) {
    				foundX = pos.x;
    				return false;
    			}
    		});
    		return foundX;
    	}
    	if (positions[nodeId]) {
    		return positions[nodeId].x;
    	}
    	return undefined;
    }

    function getY(positions, nodeId, nodeType) {
    	if (nodeType) {
    		var foundY;
    		angular.forEach(positions, function(pos) {
    			if (pos.type === nodeType) {
    				foundY = pos.y;
    				return false;
    			}
    		});
    		return foundY;
    	}
    	if (positions[nodeId]) {
    		return positions[nodeId].y;
    	}
    	return undefined;
    }

    function prepareDiagramMap(diagram) {
    	var positions = {};

    	if (diagram && diagram.positions) {
    		angular.forEach(diagram.positions, function(pos) {
    			positions[pos.name] = pos;
    		});
    	}

    	return positions;
    }

    // does not accept class for some reason (css not referenced?)
    function formatText(text) {
        return '<div xmlns="http://www.w3.org/1999/xhtml" '+
            'style="width:80px;height:30px;margin:0;padding:0;text-align:center;font-family:sans-serif;font-size:10px;display:inline-block;">' +text + '</div>';
    }

    function rectStyle(selected) {
        return selected ? ' fill="#98c9fb" stroke="#000" stroke-width="3" ' : ' fill="#98c9fb" stroke="#999" stroke-width="1.5" ';
    }

    function createImage(text, dataType, selected) {
        if (dataType === 'TASK') {
    	    var svg = '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewPort="0 0 100 100">' +
    	        '<path ' + rectStyle(selected) +' d="m17.44444,1.62501l65.11107,0l0,0c8.52975,0 15.44449,21.65819 15.44449,48.375c0,26.71674 -6.91474,48.37498 -15.44449,48.37498l-65.11107,0l0,0c-8.52972,0 -15.44444,-21.65824 -15.44444,-48.37498c0,-26.71681 6.91472,-48.375 15.44444,-48.375z"/>' +
                '<foreignObject x="10" y="40" width="80" height="40">' + formatText(text) + '</foreignObject>' +
                '</svg>';
    	} else if (dataType === 'SECTION') {
    	    var svg = '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="70" viewPort="0 0 100 70">' +
     	    '<rect ' + rectStyle(selected) +' x="0" y="0" width="100" height="70"/>' +
            '<line stroke-linecap="undefined" stroke-linejoin="undefined" y2="30" x2="98" y1="30" x1="2" stroke-width="1.5" stroke="#999" fill="none"/>' +
            '<foreignObject x="10" y="5" width="80" height="30">' + formatText(text) + '</foreignObject>' +
            '</svg>'
    	} else {
            var svg = '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewPort="0 0 100 100">' +
              '<ellipse ry="20" rx="20" cy="50" cx="50" stroke-opacity="null" ' + rectStyle(selected) +' />' +
            '</svg>';
        }


        return "data:image/svg+xml;charset=utf-8,"+ encodeURIComponent(svg);
    }

    function renderModel(model) {

    	var positions = prepareDiagramMap(model.diagram);

        var nodes = [{
            id: 'start',
            shape: 'image',
            size: 40,
            data: { name: 'start', label: 'START', start: true, type: 'START' },
            dataType: 'START',
            x: getX(positions, 'start', 'START'),
            y: getY(positions, 'start', 'START'),
            image: createImage('START', 'START')
        }];

		angular.forEach(model.tasks, function(task) {
			nodes.push({
                id: task.name,
                shape: 'image',
                dataType: 'TASK',
                data: task,
                size: 40,
                x: getX(positions, task.name),
                y: getY(positions, task.name),
                image: createImage(task.label, 'TASK')
			});
		});

        var edges = [];
		angular.forEach(model.edges, function(edge) {
			edges.push({
                from: getNodeId(edge.from),
                to: getNodeId(edge.to),
                data: edge
			});
		});

        $scope.data = {
            type: 'tasks',
            nodes: new DataSet(nodes),
            edges: new DataSet(edges)
        };

        $scope.manipulation = {
        		addEdgeMode: false
        };

        $scope.options = {
            physics: {
                enabled: false
            },
            edges: {
                arrows: 'to',
                dashes: true,
                smooth: false,
                length: 200,
                color: {
                    color: 'gray',
                    highlight: '#000'
                }

            },
            layout: {
                hierarchical: {
                    direction: "LR",
                    sortMethod: "directed"
                }
            },
            nodes: {
                color: {
                    border: 'orange',
                    background: 'yellow',
                    highlight: {
                        border: 'darkorange',
                        background: 'gold'
                    }
                }
            },
            interaction : {
            	selectConnectedEdges: false
            },
            groups: {
            },
            manipulation: {
            	 enabled: false,
            	 addEdge: function (data, callback) {
            		 if (data.to == 'start') {
            			 callback(null);
                    	 return;
                     }
                     $scope.$evalAsync(function () {
                         $scope.$emit('vis:addEdge', data, callback);
                     });
                 }
            }
        };
    }

    function renderTask(task) {

    	var positions = prepareDiagramMap(task.diagram);

    	var nodes = [{
            id: 'start',
            shape: 'image',
            size: 40,
            data: { name: 'start', label: 'START', start: true, type: 'START' },
            dataType: 'START',
            x: getX(positions, 'start', 'START'),
            y: getY(positions, 'start', 'START'),
            image: createImage('START', 'START')
        }];

		angular.forEach(task.sections, function(section) {
			nodes.push({
                id: section.name,
                shape: 'image',
                dataType: 'SECTION',
                data: section,
                size: 40,
                x: getX(positions, section.name),
                y: getY(positions, section.name),
                image: createImage(section.label, 'SECTION')
			});
		});

        var edges = [];
		angular.forEach(task.edges, function(edge) {
			edges.push({
                from: getNodeId(edge.from),
                to: getNodeId(edge.to),
                data: edge
			});
		});

        $scope.data = {
            type: 'sections',
            nodes: new DataSet(nodes),
            edges: new DataSet(edges)
        };

        $scope.options = {
            physics: {
                enabled: false
            },
            edges: {
                arrows: 'to',
                dashes: true,
                smooth: false,
                color: {
                    color: 'gray',
                    highlight: '#000'
                },
                length: 200
            },
            layout: {
                hierarchical: {
                    direction: "LR",
                    sortMethod: "directed"
                }
            },
            nodes: {
                color: {
                    border: 'orange',
                    background: 'yellow',
                    highlight: {
                        border: 'darkorange',
                        background: 'gold'
                    }
                }
            },
            interaction : {
            	selectConnectedEdges: false
            },
            groups: {
            },
            manipulation: {
            	 enabled: false,
	           	 addEdge: function (data, callback) {
	                    if (data.to == 'start') {
	                   	   return;
	                    }
	                    $scope.$evalAsync(function () {
	                        $scope.$emit('vis:addSectionEdge', data, callback);
	                    });
	                }
           }
        };
    }

    // Callbacks are fired outside the digest loop
    $scope.events = {
        selectNode: function(event) {
            $scope.$evalAsync(function () {
                $scope.$emit('vis:selectionChanged', event.nodes, event.edges);
            });
        },
        deselectNode: function(event) {
            $scope.$evalAsync(function () {
                $scope.$emit('vis:selectionChanged', event.nodes, event.edges);
            });
        },
        selectEdge: function(event) {
            $scope.$evalAsync(function () {
                $scope.$emit('vis:selectionChanged', event.nodes, event.edges);
            });
        },
        deselectEdge: function(event) {
            $scope.$evalAsync(function () {
                $scope.$emit('vis:selectionChanged', event.nodes, event.edges);
            });
        },
        doubleClick: function(event) {
            $scope.$evalAsync(function () {
                $scope.$emit('vis:doubleClick', event.nodes);
            });
        },
        onload: function(network) {
            $timeout(function() {
                network.fit();
            }, 100);
        }
    };

    $scope.$on('vis:addEdge', function ($event, data, callback) {
    	 $event.stopPropagation(); // Useful, to avoid performance leak
    	 var confirm = AddEdgeDialog.show(data, 'NewEdge', true, function(edge) {
    		 $scope.addEdge(data, edge);
    	 }, function() {
    		 callback(null);
    	 });
    });

    $scope.edge = function(edgeMode) {
    	$log.info('edgeMode:' + edgeMode);
    	if (edgeMode) {
    		$scope.manipulation = {disableEditMode : false, addEdgeMode : true};
    	} else {
    		$scope.manipulation = {disableEditMode : true, addEdgeMode: false};
    	}
    };

    $scope.addEdge = function(data, edge) {
    	var startNode;
	   	if (data.from === 'start') {
	   		startNode = {type: 'START'};
	   	} else {
	   		startNode = {name: data.from, type: "TASK"};
	   	}
	   	var e = {name: edge.name, from: startNode, to: {name: data.to, type: "TASK"}, condition: {expression: edge.expression}, status: edge.status};
	   	ModelDesign.addEdge({
	         name: $scope.name,
	         uuid: $scope.uuid
	    }, e, function(model) {
	    	$scope.model = model;
	   		renderModel(model);
	    });
    };

    $scope.addSectionEdge = function(data, edge) {
    	var startNode;
	   	 if (data.from === 'start') {
	   		 startNode = {type: 'START'};
	   	 } else {
	   		 startNode = {name: data.from, type: "SECTION"};
	   	 }
	   	 var e = {name: edge.name, from: startNode, to: {name: data.to, type: "SECTION"}, condition: {expression: edge.expression}};
	   	 ModelDesign.addSectionEdge({
	            name: $scope.name,
	            uuid: $scope.uuid,
	            screen: $scope.task.name,
	        }, e, function(model) {
	   		$scope.model = model;
	   		$scope.task = findNodeByName($scope.model.tasks, $scope.task.name);
	   		renderTask($scope.task);
	   	 });
    };

    $scope.$on('vis:addSectionEdge', function ($event, data, callback) {
    	$event.stopPropagation(); // Useful, to avoid performance leak
	   	 var confirm = AddEdgeDialog.show(data, 'NewEdge', false, function(edge) {
	   		 $scope.addSectionEdge(data, edge);
	   	 }, function() {
	   		 callback(null);
	   	 });
    });

    $scope.$on('vis:selectionChanged', function ($event, selectedNodes, selectedEdges) {
        $event.stopPropagation(); // Useful, to avoid performance leak
        if ($scope.selectedObject) {
        	if ($scope.selectedNode) {
	            var diagramNode = findDataNodeById($scope.selectedNode.name);
	            diagramNode.image = createImage($scope.selectedNode.label, diagramNode.dataType);
	            $scope.data.nodes.update(diagramNode);
        	}
            $scope.setSelectedObject( undefined, 'NODE');
        }
        if (selectedNodes && selectedNodes.length) {
            var sn = $scope.data.nodes.get(selectedNodes[0]);
            $scope.setSelectedObject( sn.data, 'NODE');
            var diagramNode = findDataNodeById(selectedNodes[0]);
            if (diagramNode && $scope.selectedNode) {
                diagramNode.image = createImage($scope.selectedNode.label, diagramNode.dataType, true);
                $scope.data.nodes.update(diagramNode);
            }
        }
        if (selectedEdges && selectedEdges.length) {
        	var edge = $scope.data.edges.get(selectedEdges[0]);
        	$scope.setSelectedObject( edge.data, 'EDGE');
        }
    });

    $scope.setSelectedObject = function(obj, type) {
    	if (!type || !obj) {
    		$scope.selectedNode = obj;
    		$scope.selectedEdge = obj;
    		$scope.selectedObject = obj;
    	} else if (type === 'NODE') {
    		$scope.selectedNode = obj;
    		$scope.selectedObject = obj;
    	} else if (type === 'EDGE') {
    		$scope.selectedEdge = obj;
    		$scope.selectedObject = obj;
    	}
    };

    $scope.$on('vis:doubleClick', function ($event, selectedNodes) {
        $event.stopPropagation(); // Useful, to avoid performance leak
        if (selectedNodes && selectedNodes.length && !$scope.task) {
           $scope.task = findNodeByName($scope.model.tasks, selectedNodes[0]);
           if ($scope.task) {
               $scope.setSelectedObject( undefined, 'NODE');
               renderTask($scope.task);
           }
        } else if (selectedNodes && selectedNodes.length && $scope.task) {
            var sn = $scope.data.nodes.get(selectedNodes[0]);
            $scope.setSelectedObject( sn.data, 'NODE');
            $scope.showSection();
        }
    });

    $scope.show = function() {
    	$scope.task = $scope.selectedNode;
    	renderTask($scope.task);
    	$scope.setSelectedObject( undefined, 'NODE');
    };

    $scope.showSection = function() {
        if ($scope.selectedNode && $scope.task) {
            $location.path('/diagram/' + $scope.name + '/' + $scope.uuid + '/' + $scope.task.name + '/' + $scope.selectedNode.name);
        }
    };

    Model.get({
		name : $scope.name,
		uuid : $scope.uuid
	}, function(model) {
	    $scope.model = model;

		if ($routeParams.taskName) {
			$scope.task = findNodeByName($scope.model.tasks, $routeParams.taskName);
			renderTask($scope.task);
		} else {
			renderModel(model);
		}

	});

    $scope.showBack = function() {
        return $scope.data && $scope.data.type == 'sections';
    };

    $scope.back = function() {
        $scope.task = undefined;
        $scope.setSelectedObject( undefined, 'NODE');
        renderModel($scope.model);
    };

    $scope.update = function(nodeName, attr, value) {
        var diagramNode = findDataNodeById(nodeName);
        if (diagramNode) {
            if (attr == 'label') {
                diagramNode.image = createImage(value, '&#9776;');
            } else {
                diagramNode[attr] = value;
            }

            $scope.data.nodes.update(diagramNode);
        }
    };

    $scope.removeNode = function(nodeName) {
    	var confirm = $mdDialog.confirm()
	        .title($translate.instant('Delete'))
	        .textContent($translate.instant('DoYouWantToDelete?'))
	        .ariaLabel('Delete')
	        .ok($translate.instant('Ok'))
	        .cancel($translate.instant('Cancel'));

    	$mdDialog.show(confirm).then(function() {
	    	if ($scope.task) {
		        ModelDesign.deleteSection({name: $scope.model.name, uuid: $scope.model.uuid, screen: $scope.task.name, section: nodeName}, function(model) {
		        	$scope.model = model;
	            	$scope.task = findNodeByName($scope.model.tasks, $scope.task.name);
	            	$scope.setSelectedObject( undefined, 'NODE');
	            	renderTask($scope.task);
		        });
	    	} else {
	    		ModelDesign.deleteTask({name: $scope.model.name, uuid: $scope.model.uuid, screen: nodeName}, function(model) {
		        	$scope.model = model;
	            	$scope.setSelectedObject( undefined, 'NODE');
	            	renderModel($scope.model);
		        });
	    	}
	    });
    };

    $scope.removeEdge = function(edgeName) {
    	var confirm = $mdDialog.confirm()
	        .title($translate.instant('Delete'))
	        .textContent($translate.instant('DoYouWantToDelete?'))
	        .ariaLabel('Delete')
	        .ok($translate.instant('Ok'))
	        .cancel($translate.instant('Cancel'));

	    $mdDialog.show(confirm).then(function() {
	    	if ($scope.task) {
		        ModelDesign.deleteSectionEdge({name: $scope.model.name, uuid: $scope.model.uuid, screen: $scope.task.name, edge: edgeName}, function(model) {
		        	$scope.model = model;
	            	$scope.task = findNodeByName($scope.model.tasks, $scope.task.name);
	            	renderTask($scope.task);
		        });
	    	} else {
	    		ModelDesign.deleteEdge({name: $scope.model.name, uuid: $scope.model.uuid, edge: edgeName}, function(model) {
		        	$scope.model = model;
	            	renderModel($scope.model);
		        });
	    	}
	    });
    };

    $scope.save = function() {
    	if ($scope.networkObject && $scope.networkObject.network) {
    		$scope.networkObject.network.storePositions();

    		var diagram = {positions:[]};
		    angular.forEach($scope.data.nodes, function(node) {
		  		  var id = node.id;
		  		  diagram.positions.push({name: node.data.name, x : node.x, y: node.y, type: node.data.type});
		    });

		    if ($scope.task) {
		    	$scope.task.diagram = diagram;
		    } else {
		    	$scope.model.diagram = diagram;
		    }
	        Model.update({name: $scope.name, uuid: $scope.uuid}, $scope.model, function() {
	        	$mdDialog.show(
	    		      $mdDialog.alert()
	    		        .parent(angular.element(document.body))
	    		        .clickOutsideToClose(true)
	    		        .title($translate.instant('ModelStored'))
	    		        .textContent($translate.instant('ModelStoredSuccess'))
	    		        .ariaLabel($translate.instant('ModelStored'))
	    		        .ok('OK')
	    		);
	        });

    	}

    };

    $scope.newEdgeName = function(from, toName) {
    	var result = "from";
    	if (from.type === 'START') {
    		result = result + 'Start';
    	} else {
    		result = result + $filter("capitalize")(from.name);
    	}
    	result = result + "To" + $filter("capitalize")(toName);
    	return result;
    };

    $scope.addSection = function() {
		AddObjectDialog.show('AddSection', 'AddSectionMessage', function(newSection) {
			newSection.widgets = [];
			var from = (!$scope.selectedNode || $scope.selectedNode.start) ? {type: 'START'} : {type: 'SECTION', name: $scope.selectedNode.name};
			var edgeName = $scope.newEdgeName(from, newSection.name);
			var edge = {condition: {expression: "true"}, name: edgeName, from: from, to: {name: newSection.name, type: 'SECTION'}};

			// stores to the server
			ModelDesign.addSection({
                name: $scope.name,
                uuid: $scope.uuid,
                screen: $scope.task.name
            }, {section: newSection, edge: edge}, function(model) {
            	$scope.model = model;
            	$scope.task = findNodeByName($scope.model.tasks, $scope.task.name);
            	renderTask($scope.task);
            });
		});
	};

    $scope.addTask = function() {
		AddObjectWithRoleDialog.show('AddTask', 'AddTaskMessage', function(newTask) {
			var from = (!$scope.selectedNode || $scope.selectedNode.start) ? {type: 'START'} : {type: 'TASK', name: $scope.selectedNode.name};
			var edgeName = $scope.newEdgeName(from, newTask.name);
			var edge = {
			    condition: {expression: 'true'},
			    name: edgeName,
			    from: from,
			    to: {name: newTask.name, type: 'TASK'}
            };
            newTask.configurations = [{
                condition: { expression: 'true' },
                potentialOwner: { name: newTask.role },
                businessAdministrator: {name : newTask.managerRole }
            }];
            newTask.sections = [{
                name: 'section1',
                label: 'Section 1'
            }];
            newTask.commands = [{
                name: 'complete',
            	label: 'Complete',
                type: 'COMPLETE',
                primary: true
            }, {
                name: 'save',
            	label: 'Save',
                type: 'SAVE',
                primary: false
            }];
            newTask.edges = [
            	{
            		condition: {expression: 'true'},
            		name: "toSection1",
            		from: {type: 'START'},
            		to: {name: "section1", type: 'SECTION'}
            	}
            ];
			ModelDesign.addTask({
                name: $scope.name,
                uuid: $scope.uuid
            }, {task: newTask, edge: edge}, function(model) {
            	$scope.model = model;
            	renderModel($scope.model);
            });
		});
    };

}]);

