import identityTemplate from './identity.html';

angular.module('org.saga.widget.identity', [
   'org.saga.service'
])

   .directive('identityWidget', ['PropertiesLoader', '$log', '$rootScope', 'Identity', 'IdentityImages', 'InstanceExpression',
      function(PropertiesLoader, $log, $rootScope, Identity, IdentityImages, InstanceExpression) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: identityTemplate,
            scope: {
               widget: '=',
               editable: '=',
               required: '=',
               instance: '=',
               identity: '=?'
            },
            link: function(scope, element, attrs, form) {
               scope.form = form;
            },
            controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

               $scope.searching = false;
               $scope.identities = null;
               PropertiesLoader.load($scope, DefaultProperties.identity);
               $scope.searchBox = {};

               $scope.getImage = function(identity) {
                  return IdentityImages[(identity) ? 'yes' : 'no'];
               };

               if (InstanceExpression.eval($scope.setLoggedUser, $scope.instance)) {
                  if (!$scope.identity) {
                     $scope.identity = {};
                  }
                  $scope.identity.name = $rootScope.account.name;
               }

               if ($scope.identity && $scope.identity.name) {
                  // reload identity
                  Identity.get({ name: $scope.identity.name }, function(identity) {
                     $scope.identity = identity;
                  });
               } else {
                  $scope.identity = null;
               }

               if (!$scope.identity || !$scope.identity.name) {
                  $scope.searching = $scope.editable;
               }

               $scope.clearIdentity = function() {
                  $scope.identity = null;
                  $scope.identities = null;
                  $scope.searchBox.searchText = '';
                  $scope.searching = $scope.editable;
               };

               $scope.queryIdentities = function(searchText) {
                  if (searchText) {
                     return Identity.search({ query: searchText });
                  } else {
                     return [];
                  }
               };

               $scope.searchIdentities = function() {
                  $scope.searching = true;
                  Identity.search({ query: $scope.searchBox.searchText }, function(list) {
                     $scope.loading = false;
                     if (list.length != 1) {
                        $scope.identities = list;
                     } else {
                        $scope.searching = false;
                        $scope.selectIdentity(list[0]);
                     }
                  });
               };

               $scope.showSearch = function() {
                  $scope.searching = !$scope.searching;
               };

               $scope.selectIdentity = function(identity) {
                  $scope.searching = false;
                  $scope.identity = identity;
               };

               $scope.isValid = function() {
                  if ($scope.required && (!$scope.identity || !$scope.identity.name)) {
                     return false;
                  }
                  return true;
               };

            }]
         };
      }]);
