import updDocStatusTemplate from './upddocstatus.html';

angular.module('org.saga.upddocstatus', [
   'org.saga.service',
   'isolateForm'
])

   .factory('UpdDocStatus', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(selectedDocument, callback, labels) {
            function DialogController($scope, $log, $mdDialog) {

               $scope.document = selectedDocument;
               $scope.labels = labels;
               $scope.document.description = $scope.document.template.description;

               $scope.cancel = function() {
                  delete $scope.document.description;
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $mdDialog.hide($scope.document);
               };

            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: updDocStatusTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: false
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
