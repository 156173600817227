import operationTableTemplate from './operationtable.html';

angular.module('org.saga.operationtable', [
   'org.saga.instancelist'
])

   .directive('operationTable', function() {
      return {
         restrict: 'E',
         template: operationTableTemplate,
         link: function(scope, elem, attrs) {
            scope.name = attrs.name;
         },
         controllerAs: 'ctrl',
         scope: {
            instances: '=',
            headerText: '@',
            humantasks: '=',
            promise: '=',
            totalPages: '=',
            onPageAction: '&'
         },
         controller: ['$scope', function($scope) {

            $scope.open = true;
            $scope.page = 0;

            $scope.pageOptions = { page: $scope.page, totalPages: $scope.totalPages };

            $scope.displayOptions = {
               tasks: true,
               filter: true,
               hidePicture: true,
               hideTitle: true,
               pageNavigator: true
            };

            $scope.toggle = function() {
               $scope.open = !$scope.open;
            };

            $scope.pageAction = function(action) {
               if ($scope.onPageAction && action) {
                  if ('NEXT' === action) {
                     $scope.page++;
                  } else if ('FIRST' === action) {
                     $scope.page = 0;
                  } else if ('LAST' === action) {
                     $scope.page = $scope.totalPages - 1;
                  } else if ('PREVIOUS' === action) {
                     $scope.page--;
                  }

                  if ($scope.page < 0) {
                     $scope.page = 0;
                  }
                  if ($scope.page >= $scope.totalPages) {
                     $scope.page = $scope.totalPages - 1;
                  }

                  $scope.onPageAction({ page: $scope.page });
               }
            };

            $scope.$watch('page', function() {
               $scope.pageOptions = { page: $scope.page, totalPages: $scope.totalPages };
            });

            $scope.$watch('totalPages', function() {
               $scope.pageOptions = { page: $scope.page, totalPages: $scope.totalPages };
            });

         }]
      };
   });
