import gaugeTemplate from './gauge.html';
import Highcharts from 'highcharts/highcharts'
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';

highchartsMore(Highcharts);
solidGauge(Highcharts);

angular.module('org.saga.gauge', [
   'org.saga.service'
])

   .directive('gauge', function() {
      return {
         restrict: 'E',
         transclude: true,
         template: gaugeTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '='
         },
         controller: ['$scope', '$timeout', function($scope, $timeout) {
            let chartConfig = {
               chart: {
                  type: 'solidgauge',
                  backgroundColor: 'transparent',
                  renderTo: 'chart1',
                  height: '300px',
                  reflow: true
               },

               title: null,

               pane: {
                  center: ['50%', '70%'],
                  size: '120%',
                  startAngle: -90,
                  endAngle: 90,
                  background: {
                     backgroundColor: '#EEE',
                     innerRadius: '90%',
                     outerRadius: '100%',
                     shape: 'arc',
                     borderColor: 'transparent'
                  }
               },

               tooltip: {
                  enabled: false
               },

               // the value axis
               yAxis: {
                  min: 0,
                  max: 100,
                  stops: [
                     [0.1, '#e74c3c'], // red
                     [0.5, '#f1c40f'], // yellow
                     [0.9, '#2ecc71'] // green
                  ],
                  minorTickInterval: null,
                  tickPixelInterval: 400,
                  tickWidth: 0,
                  gridLineWidth: 0,
                  gridLineColor: 'transparent',
                  labels: {
                     enabled: false
                  },
                  title: {
                     enabled: false
                  }
               },

               credits: {
                  enabled: false
               },

               plotOptions: {
                  solidgauge: {
                     innerRadius: '90%',
                     dataLabels: {
                        y: -45,
                        borderWidth: 0,
                        useHTML: true
                     }
                  }
               },

               series: [{
                  data: [($scope.ngModel) ? (Math.round($scope.ngModel.sumOfWork * 10000 / $scope.ngModel.work) / 100) : 0],
                  dataLabels: {
                     borderWidth: 0,
                     y: -20,
                     format: '<span style="font-size:36px;font-weight:bold;color:black">{y}%</span>'
                  }
               }]
            };

            $timeout(function() {
               $scope.chart = Highcharts.chart('chart1', chartConfig);
            }, 200);

            $scope.$watch('ngModel', function(newVal) {
               if ($scope.chart) {
                  $scope.chart.series[0].update({
                     data: [Math.round(newVal.sumOfWork * 10000.0 / newVal.work) / 100]
                  }, true);
               }
            });
         }]
      };
   });

