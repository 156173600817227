import addDocumentDialogTemplate from './adddocument-dialog.html';
import addDocumentTemplate from './adddocument.html';

angular.module('org.saga.adddocument', [
   'org.saga.service',
   'isolateForm'
])

   .directive('addDocument', ['EnumerationCache', 'ListUtils', function(EnumerationCache, ListUtils) {
      return {
         restrict: 'E',
         template: addDocumentTemplate,
         scope: {
            ngModel: '=',
            onSelect: '&',
            allowedTypes: '=',
            labels: '=',
            types: '=',
            showUpload: '=',
            showAdd: '='
         },
         controller: ['$scope', function($scope) {
            const filterTypes = (types) => {
               $scope.types = ListUtils.filter(types, $scope.allowedTypes, function(item) {
                  return $scope.allowedTypes.indexOf(item.name) >= 0;
               });
            };

            if ($scope.types && $scope.types.length > 0) {
               filterTypes($scope.types);
            } else {
               EnumerationCache.asArray('DocumentType', (types) => filterTypes(types));
            }

            $scope.addFile = function(files) {
               angular.forEach(files, function(file) {
                  $scope.ngModel.file = file;
               });
            };

            $scope.done = function() {
               $scope.onSelect({ document: $scope.ngModel });
            };

         }]
      };
   }])


   .factory('AddDocumentDialog', ['$mdDialog', '$log', function($mdDialog, $log) {

      return {
         show: function(newDocument, types, allowedTypes, labels, callback) {
            function DialogController($scope, $log, $mdDialog) {

               $scope.document = { type: newDocument.type };
               $scope.labels = labels;
               $scope.allowedTypes = allowedTypes;
               $scope.types = types;

               $scope.cancel = function() {
                  $mdDialog.cancel();
               };

               $scope.ok = function() {
                  $mdDialog.hide($scope.document);
               };

            }

            $mdDialog.show({
               controller: ['$scope', '$log', '$mdDialog', DialogController],
               template: addDocumentDialogTemplate,
               parent: angular.element(document.body),
               clickOutsideToClose: true
            })
               .then(function(answer) {
                  $log.info(answer);
                  callback(answer);
               }, function() {
                  // callback is not called
               });

         }
      };
   }]);
