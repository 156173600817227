const {
   startOfDay,
   formatISO,
   isSameDay,
   isDate,
   set
} = require('date-fns');
import timesheetTemplate from './timesheet.html';

angular
   .module('org.saga.widget.timesheet', ['org.saga.service', 'ngMaterialDatePicker', 'isolateForm'])
   .filter('getLabel', [
      '$parse',
      function ($parse) {
         return function (item, labelExpression) {
            if (!labelExpression) {
               return item;
            }
            const parsedExpression = $parse(labelExpression);
            return parsedExpression({ item: item });
         };
      }
   ])
   .filter('dynamicExpFilter', [
      '$parse',
      function ($parse) {
         return function (items, filterExpression) {
            if (!filterExpression) {
               return items;
            }
            const parsedExpression = $parse(filterExpression);
            return items.filter((item) => {
               return parsedExpression({item: item });
            });
         };
      }
   ])
   .filter('filterSubprojects', [
      '$parse',
      function ($parse) {
         return function (items, filterExpression, project) {
            if (!project || !filterExpression) {
               return items;
            }
            const parsedExpression = $parse(filterExpression);
            return items.filter((item) => {
               return parsedExpression({ item: item, project: project });
            });
         };
      }
   ])
   .filter('duration', function () {
      return function (durationInMs) {
         const totalSeconds = Math.floor(durationInMs / 1000);
         const hours = Math.floor(totalSeconds / 3600);
         const minutes = Math.floor((totalSeconds % 3600) / 60);
         const twoPlaceMinutes = minutes===0 ? '00' : minutes;

         return `${hours}:${twoPlaceMinutes}`;
      };
   })
   .directive('timesheetWidget', [
      'PropertiesLoader',
      'DefaultProperties',
      '$translate',
      function (PropertiesLoader, DefaultProperties, $translate) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: timesheetTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               required: '=',
               output: '=?'
            },
            link: function (scope, element, attrs, form) {
               scope.form = form;
            },
            controller: [
               '$scope', '$parse',
               function ($scope, $parse) {
                  PropertiesLoader.load($scope, DefaultProperties.timesheet);

                  $scope.output = $scope.output ?? {
                     totalDuration: 0,
                     totalManDays: 0,
                     records: []
                  };
                  $scope.newRecord = {};

                  function getValueFromAttribute(attribute) {
                     if (typeof attribute === 'string') {
                        return $scope.instance.attributes[attribute];
                     } else {
                        return attribute;
                     }
                  }

                  function evalSubprojectsExpression(expression) {
                     const parsedExpression = $parse(expression);
                     return parsedExpression({project: $scope.newRecord.project});
                  }

                  $scope.projects = getValueFromAttribute($scope.projects);
                  $scope.subprojects = $scope.subprojectsExpression ? evalSubprojectsExpression($scope.subprojectsExpression) : getValueFromAttribute($scope.subprojects);
                  $scope.activityTypes = getValueFromAttribute($scope.activityTypes);

                  function setTotalDuration () {
                     $scope.output.totalDuration = $scope.output.records.reduce(
                        (acc, timesheet) => acc + timesheet.duration,
                        0
                     );
                     $scope.output.totalManDays = $scope.output.records.reduce(
                        (acc, timesheet) => acc + timesheet.manDays,
                        0
                     );
                  }

                  $scope.deleteRecord = function (recordKey) {
                     $scope.output.records.splice(recordKey, 1);
                     setTotalDuration()
                  };

                  $scope.editRecord = function (record, recordKey) {
                     const project = $scope.projects.find((project) => project.id === record.project.id);

                     $scope.newRecord = {
                        ...record,
                        project: project,
                        date: new Date(record.date),
                        start: new Date(record.start),
                        end: new Date(record.end)
                     };
                     $scope.deleteRecord(recordKey);
                  };

                  $scope.formatAndSave = function (record) {
                     const data = {
                        ...record,
                        project: {
                           id: record.project.id,
                           name: record.project.name
                        },
                        date: formatISO(record.date, { representation: 'date' }),
                        start: formatISO(record.start),
                        end: formatISO(record.end),
                        duration: record.end - record.start,
                        manDays: (record.end - record.start) / 1000 / 60 / 60 / 8
                     };
                     $scope.output.records.push(data);
                     $scope.output.records.sort((a, b) => {
                        const dateA = new Date(a.start);
                        const dateB = new Date(b.start);
                        return dateA - dateB;
                     });
                     setTotalDuration();
                     $scope.newRecord = {};
                  };

                  const checkRecordIntervalOverlap = (newRecord) => $scope.output.records.some((rec) => {
                     const recStartTime = new Date(rec.start);
                     const recEndTime = new Date(rec.end);
                     return (
                        (newRecord.start <= recStartTime && newRecord.end > recStartTime) ||
                        (newRecord.start < recEndTime && newRecord.end >= recEndTime) ||
                        (newRecord.start >= recStartTime && newRecord.end <= recEndTime)
                     );
                  });

                  $scope.saveRecord = function (newRecord) {
                     $scope.error = false;
                     const recordIntervalOverlap = checkRecordIntervalOverlap(newRecord);

                     if (recordIntervalOverlap) {
                        $scope.error = true;
                     } else {
                        $scope.formatAndSave(newRecord);
                     }
                  };

                  $scope.$watch('newRecord.date', (val) => {
                     if (val) {
                        $scope.startOfSelectedDay = startOfDay($scope.newRecord.date);
                        $scope.endOfSelectedDay = set($scope.newRecord.date, {hours: 23, minutes: 45})

                        if ($scope.newRecord.start) {
                           //in case of editing, we need to update the date of the start and end time
                           $scope.newRecord.start = new Date(
                              val.getFullYear(),
                              val.getMonth(),
                              val.getDate(),
                              $scope.newRecord.start.getHours(),
                              $scope.newRecord.start.getMinutes()
                           );
                        }
                     }
                  });

                  $scope.$watch('newRecord.start', (val) => {
                     $scope.error = false;
                     if (val) {
                        //in case of editing, we need to update the date of the end time
                        if ($scope.newRecord.end && !isSameDay(val, $scope.newRecord.end)) {
                           $scope.newRecord.end = new Date(
                              val.getFullYear(),
                              val.getMonth(),
                              val.getDate(),
                              $scope.newRecord.end.getHours(),
                              $scope.newRecord.end.getMinutes()
                           );
                        }
                     }
                  });

                  function getDateObject(val) {
                     if (typeof val === 'string' && val !== '') {
                        return new Date(val);
                     } else if (isDate(val)) {
                        return val;
                     }
                  }

                  $scope.$watch('instance.attributes.' + $scope.minDate, (val) => {
                     $scope.minDateObject = getDateObject(val);
                  });

                  $scope.$watch('instance.attributes.' + $scope.maxDate, (val) => {
                     $scope.maxDateObject = getDateObject(val);
                  });

                  $scope.$watch('newRecord.project', (val) => {
                    if ($scope.subprojectsExpression) {
                      $scope.subprojects = evalSubprojectsExpression($scope.subprojectsExpression);
                    }
                  });
               }
            ]
         };
      }
   ]);
