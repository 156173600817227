import personTemplate from './person.html'

angular.module('org.saga.widget.person', [
	'org.saga.service',
	'org.saga.personlist'
])

	.directive('personWidget', ['PropertiesLoader', 'Person', 'EvalFactory', 'PersonFactory', function(PropertiesLoader, Person, EvalFactory, PersonFactory) {
		return {
			restrict: 'E',
			require: '^form',
			controllerAs: 'ctrl',
			template: personTemplate,
			scope: {
				widget: '=',
				editable: '=',
				instance: '=',
				required: '=',
				client: '=?'
			},
			link: function(scope, element, attrs, form) {
				scope.form = form;
			},
			controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

				$scope.searching = false;
				$scope.searchForm = {};

				PropertiesLoader.load($scope, DefaultProperties.person);

				$scope.reload = function() {
					if ($scope.client && $scope.client.id) {
						Person.get({id : $scope.client.id}, function(client) {
							$scope.client = client;
						});
					}
				};

				$scope.$watch("client", function(newClient, oldClient) {
					if (newClient && oldClient && newClient.id===oldClient.id) {
						return;
					}
					$scope.reload();
				});

				$scope.clearPerson = function() {
					$scope.client = null;
				};

				$scope.selectPerson = function(person) {
					$scope.searching = false;
					$scope.client = person;
				};

				$scope.getPersonIdentification = function(person) {
					return EvalFactory.run($scope.personIdentification, person);
				};

				$scope.searchPerson = function() {
					Person.search({id : $scope.searchForm.crmId, personalId: $scope.searchForm.personalId, idCardNumber: $scope.searchForm.idCardNumber}, function(list){
						if (list.length!=1) {
							$scope.searching = true;
							$scope.persons = list;
						} else {
							$scope.selectPerson(list[0]);
						}
					});
				};

				$scope.getImage = function(person) {
					if (person && person.id) {
						return 'account_circle';
					} else {
						return 'help';
					}
				};

				$scope.isValid = function() {
					if ($scope.required && (!$scope.client || !$scope.client.id)) {
						return false;
					}
					return true;
				};

				$scope.getAddress = PersonFactory.address;

			}]
		};
	}]);
