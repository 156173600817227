import modelTableTemplate from './model-table.html';

angular.module('org.saga.modeltable', [
   'org.saga.service'
])

   .directive('modelTable', function() {
      return {
         restrict: 'E',
         template: modelTableTemplate,
         scope: {
            ngModel: '=',
            newInstance: '&',
            tableHeader: '=',
            fastSelect: '=',
            searchBoxVisible: '=?'
         },
         controller: ['$scope', function($scope) {

            $scope.options = {};

            $scope.selectModel = function(model) {
               if ($scope.newInstance) {
                  $scope.newInstance({ model: model });
               }
            };

            $scope.runModel = function(model) {
               if ($scope.fastSelect) {
                  $scope.selectModel(model);
               }
            };

            $scope.clearFilter = function() {
               $scope.options.query = undefined;
               document.getElementById('search-box').focus();
            };

         }]
      };
   });
