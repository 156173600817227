import widgetDetailTemplate from './widgetdetail.html';

angular.module('org.saga.widgetdetail', [
   'org.saga.service',
   'org.saga.jsontext'
])

   .directive('widgetDetail', ['Widget', 'Utils', function(Widget, Utils) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: widgetDetailTemplate,
         scope: {
            selectedWidget: '='
         },
         controller: ['$scope', function($scope) {

            $scope.widths = [25, 50, 75, 100];

            var blist = Utils.getProperties($scope.selectedWidget.binding);

            var widgetName = function(fullWidgetName) {
               if (!fullWidgetName) {
                  return '';
               }
               var i = fullWidgetName.indexOf('-');
               return fullWidgetName.substring(0, i);
            };

            Widget.get({ name: widgetName($scope.selectedWidget.ui) }, function(widgetDef) {
               angular.forEach(widgetDef.bindings, function(b) {
                  if (blist.indexOf(b) < 0) {
                     $scope.selectedWidget.binding[b] = '';
                  }
               });
            });

            $scope.isEditable = function(value) {
               return !angular.isObject(value) && !angular.isArray(value);
            };

            $scope.getWidths = (query) => {
               let lowercaseQuery = angular.$$lowercase(query);
               return query ? widths.filter((width) => {
                  return (width.indexOf(lowercaseQuery) === 0);
               }) : widths;
            };

         }]
      };
   }]);

