import graphMenuTemplate from './graphmenu.html';

angular.module('org.saga.graphmenu', [
   'org.saga.service'
])

   .directive('graphMenu', function() {
      return {
         restrict: 'E',
         template: graphMenuTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '=',
            onChange: '&'
         },
         controller: ['$scope', function($scope) {

            $scope.graph = function(shift, interval) {
               $scope.ngModel.shift = shift;
               $scope.ngModel.interval = interval;

               $scope.onChange({ newInterval: $scope.ngModel });
            };
         }]
      };
   });

