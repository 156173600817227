import sectionTemplate from './section.html';

angular.module('org.saga.section', [
   'org.saga.service',
   'org.saga.customize',
   'org.saga.changelabel'
])

   .directive('screenSection', ['$log', '$rootScope', 'Utils', 'InstanceExpression', 'Edge', 'CustomizeSectionDialog', 'ModelDesign', 'WidgetFactory',
      function($log,
               $rootScope,
               Utils,
               InstanceExpression,
               Edge,
               CustomizeSectionDialog,
               ModelDesign,
               WidgetFactory) {
         return {
            restrict: 'E',
            controllerAs: 'ctrl',
            template: sectionTemplate,
            scope: {
               screen: '=',
               section: '=',
               instance: '=',
               humantask: '='
            },
            controller: ['$scope', function($scope) {

               $scope.renameMode = false;
               $scope.designModel = $rootScope.designModel;

               $scope.computeColspan = (widget) => {
                  if (!widget.width || widget.width === 100 || widget.width === '100') {
                     return 4;
                  } else if (widget.width === 75 || widget.width === '75') {
                     return 3;
                  } else if (widget.width === 50 || widget.width === '50') {
                     return 2;
                  } else if (widget.width === 25 || widget.width === '25') {
                     return 1;
                  }
                  return 4;
               };

               $scope.lines = [];
               let currentLine = [];
               let lineLength = 0;
               angular.forEach($scope.section.widgets, (w) => {
                  currentLine.push(w);
                  lineLength += $scope.computeColspan(w);
                  if (lineLength >= 4) {
                     $scope.lines.push(currentLine);
                     currentLine = [];
                     lineLength = 0;
                  }
               });

               $scope.widgetAction = (widget, action, data) => {
                  if (action === 'SECTION_COMPLETE') {
                     Edge.addSnapshot($scope.instance, $scope.screen, $scope.section, $scope.humantask);

                     $scope.sectionOpen = false;
                  }
               };

               $scope.locked = () => {
                  return Edge.isLocked($scope.section);
               };

               $scope.sectionOpen = !$scope.locked($scope.section);

               $scope.restoreSection = () => {
                  Edge.restoreSnapshot($scope.instance, $scope.screen, $scope.section, $scope.humantask);
                  $scope.sectionOpen = true;
               };

               $scope.noPadding = (w) => {
                  return !$scope.designModel.showInvisible && !InstanceExpression.get(w.visible, $scope.instance, true);
               };

               $scope.getUiHtml = (w) => {
                  return WidgetFactory.generate(w, {
                     widgetAction: true,
                     formLock: true,
                     timestamp: false,
                     showInvisible: $scope.designModel.showInvisible
                  });
               };

               $scope.evaluateExpression = InstanceExpression.get;

               $scope.show = () => {
                  if (!$scope.renameMode) {
                     $scope.toggleSection();
                  }
               };

               $scope.toggleSection = () => {
                  $scope.sectionOpen = !$scope.sectionOpen;
               };

               // design features

               $scope.addWidget = () => {
                  $scope.section.widgets.push({
                     ui: 'admin-add-widget',
                     name: 'adminAdd',
                     visible: true,
                     initIndex: $scope.section.widgets.length,
                     avoidCustomization: true
                  });
               };

               $scope.dropWidget = (index, item) => {
                  $scope.section.widgets.splice(index, 0, {
                     ui: 'admin-add-widget',
                     name: 'adminAdd',
                     visible: true,
                     initType: item.name,
                     initIndex: index,
                     avoidCustomization: true
                  });
                  return true;
               };

               $scope.renameSectionCompleted = (withToggle) => {
                  $scope.renameMode = false;
                  $scope.saveSection();
                  if (withToggle) {
                     $scope.toggleSection();
                  }
               };

               $scope.renameSectionStarted = () => {
                  $scope.renameMode = true;
               };

               $scope.saveSection = () => {
                  ModelDesign.editSection({
                     name: $scope.instance.name,
                     uuid: $scope.instance.uuid,
                     screen: $scope.screen.name,
                     section: $scope.section.name
                  }, $scope.section, () => {
                     // do nothing on success
                  }, () => {
                     // do nothing on error
                  });
               };

               $scope.customize = () => {
                  CustomizeSectionDialog.show($scope.section.widgets, () => {
                     $scope.saveSection();
                  });
               };

            }]
         };
      }]);
