import modelListTemplate from './modellist.html';

angular.module('org.saga.widget.modellist', [
   'org.saga.service',
   'org.saga.modeltable'
])

   .directive('modellistWidget', ['PropertiesLoader', 'Model', function(PropertiesLoader, Model) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: modelListTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            model: '=?',
            onWidgetAction: '&'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', 'ListUtils', 'InstanceExpression', function($scope, DefaultProperties, ListUtils, InstanceExpression) {

            PropertiesLoader.load($scope, DefaultProperties.modellist);

            Model.query(function(models) {
               $scope.models = ListUtils.filter(models, $scope.filter, function(item) {
                  return (InstanceExpression.eval($scope.filter, $scope.instance, item)); // jshint ignore:line
               });
            });

            $scope.selectModel = function(model) {
               $scope.model = { name: model.name, uuid: model.uuid };
               if ($scope.closeSection) {
                  $scope.onWidgetAction({ widget: $scope.widget, action: 'SECTION_COMPLETE', data: {} });
               }
            };

         }]
      };
   }]);
