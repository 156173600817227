import companyTemplate from './company.html';

angular.module('org.saga.widget.company', [
   'org.saga.service',
   'org.saga.companylist'
])

   .directive('companyWidget', ['PropertiesLoader', 'Company', function(PropertiesLoader, Company) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: companyTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            required: '=',
            client: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            $scope.searching = false;
            $scope.searchForm = {};

            PropertiesLoader.load($scope, DefaultProperties.company);

            $scope.reload = function() {
               if ($scope.client && $scope.client.id) {
                  Company.get({ id: $scope.client.id }, function(client) {
                     $scope.client = client;
                  });
               }
            };

            $scope.clearCompany = function() {
               $scope.client = null;
            };

            $scope.selectCompany = function(company) {
               $scope.searching = false;
               $scope.client = company;
            };

            $scope.searchCompany = function() {
               Company.search({ taxId: $scope.searchForm.taxId, name: $scope.searchForm.companyName }, function(list) {
                  ;
                  if (list.length !== 1) {
                     $scope.searching = true;
                     $scope.companies = list;
                  } else {
                     $scope.selectCompany(list[0]);
                  }
               });
            };

            $scope.getImage = function(company) {
               if (company && company.id) {
                  return 'business';
               } else {
                  return 'help';
               }
            };

            $scope.isValid = function() {
               if ($scope.required && (!$scope.client || !$scope.client.id)) {
                  return false;
               }
               return true;
            };

            $scope.reload();

         }]
      };
   }]);
