import radioTemplate from './radio.html';

angular.module('org.saga.widget.radio', [
   'org.saga.service'
])

   .directive('radioWidget', ['PropertiesLoader', 'InstanceExpression', '$timeout', function(PropertiesLoader, InstanceExpression, $timeout) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: radioTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            selected: '=?',
            onWidgetAction: '&'
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.radio);

            if ($scope.buttonMode === 'false') {
               $scope.buttonMode = false;
            }

            $scope.itemDisabled = function(key, item) {
               var exp = $scope.disabled[key];
               if (!exp) {
                  return false;
               }
               var result = InstanceExpression.eval(exp, $scope.instance, item); // jshint ignore:line
               return result;
            };

            $scope.select = function(item) {
               $scope.selected = item;
               if ($scope.selected && $scope.closeSection) {
                  $timeout($scope.closeSectionFn, 100);
               }
            };

            $scope.closeSectionFn = function() {
               $scope.onWidgetAction({ widget: $scope.widget, action: 'SECTION_COMPLETE', data: {} });
            };
         }]
      };
   }]);
