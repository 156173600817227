import productListTemplate from './productlist.html';

angular.module('org.saga.widget.productlist', [
   'org.saga.service',
   'org.saga.producttable'
])

   .directive('productlistWidget', ['PropertiesLoader', '$log', 'ListUtils', 'Product', 'InstanceExpression', function(PropertiesLoader, $log, ListUtils, Product, InstanceExpression) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: productListTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            client: '=?',
            products: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.productlist);

            // we have to remember selected values to return them back to collection
            $scope.selected = {};
            if ($scope.products) {
               angular.forEach($scope.products, function(p) {
                  $scope.selected[p.id] = p.selected;
               });
            }

            $scope.reload = function() {
               if ($scope.client && $scope.client.id) {
                  Product.query({ clientId: $scope.client.id }, function(products) {
                     $scope.products = ListUtils.filter(products, $scope.filter, function(item) {
                        return (InstanceExpression.eval($scope.filter, $scope.instance, item)); // jshint ignore:line
                     });
                     angular.forEach($scope.products, function(p) {
                        p.selected = ($scope.selected[p.id]);
                     });
                  });
               }
            };

            $scope.$watch('client', function() {
               $scope.reload();
            });
         }]
      };
   }]);
