import textTemplate from './text.html';

angular.module('org.saga.widget.text', [
   'org.saga.service'
])

   .directive('textWidget', ['PropertiesLoader', '$log', 'DefaultProperties', '$translate', function(PropertiesLoader, $log, DefaultProperties, $translate) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: textTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            required: '=',
            value: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', function($scope) {

            PropertiesLoader.load($scope, DefaultProperties.text);

            $scope.autoHideWarnings = !($scope.showValidateWarnings === 'force');

            $scope.getErrorMessage = function(message, value) {
               return $translate.instant($scope.errors[message], { value: value });
            };
         }]
      };
   }]);
