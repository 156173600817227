angular.module('org.saga.users', [
   'org.saga.service',
   'org.saga.usercard',
   'org.saga.toolbar'
])
   .controller('UsersController', ['$scope', '$http', 'Users', 'Settings', function($scope, $http, Users, Settings) {

      Settings.get({}, function(result) {
         $scope.editMode = result.editAllowed;
      });

      $scope.newUser = { displayName: '', email: '', imageUrl: '', roles: [{ name: 'USER' }] };

      $scope.refresh = function() {
         Users.query({}, function(users) {
            $scope.users = users;
         });
      };

      $scope.userChanged = function(user) {
         $scope.refresh();
         $scope.newUser = { displayName: '', email: '', imageUrl: '', roles: [{ name: 'USER' }] };
      };

      $scope.refresh();

   }]);
