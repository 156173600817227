import flowMenuTemplate from './flowmenu.html';

angular.module('org.saga.flowmenu', [])

   .directive('flowMenu', function() {
      return {
         restrict: 'E',
         transclude: true,
         template: flowMenuTemplate,
         controllerAs: 'ctrl',
         scope: {
            ngModel: '='
         },
         controller: function() {
         }
      };
   });
