angular.module('org.saga.clientClaim', ['org.saga.service'])
   .controller('ClaimClientTaskController', ['$scope', '$location', '$routeParams', 'TaskUrl', '$http', function($scope, $location, $routeParams, TaskUrl, $http) {
      const taskId = $routeParams.taskid;
      const token = $routeParams.token;

      $http.post(`api/deeplink/claim/validate/${taskId}/${token}`).then(function(linkValid) {
         if (linkValid.data === true) {
            const taskUrl = TaskUrl.get(taskId, 'client');
            $location.path(taskUrl);
         } else {
            $scope.linkStatus = 'ClaimClientTaskInvalidLink';
         }
      }, function() {
         $scope.linkStatus = 'ClaimClientTaskError';
      });
   }
   ]);
