angular.module('org.saga.design.section', [
   'org.saga.service'
])

   .controller('SectionDesignerController', ['$scope', '$rootScope', '$routeParams', 'Task', 'ModelDesign', function($scope, $rootScope, $routeParams, Task, ModelDesign) {

      $scope.name = $routeParams.name;
      $scope.uuid = $routeParams.uuid;
      $scope.screenName = $routeParams.screenName;
      $scope.sectionName = $routeParams.sectionName;

      $rootScope.designModel = { designMode: true, showDebug: false, showInvisible: false };

      // TODO: fix it for overview
      Task.get({ name: $scope.name, uuid: $scope.uuid, taskName: $scope.screenName }, function(screen) {
         $scope.screen = screen;
         $scope.ht = { name: screen.name, label: screen.label };
         ModelDesign.getSection({
            name: $scope.name,
            uuid: $scope.uuid,
            screen: $scope.screenName,
            section: $scope.sectionName
         }, function(section) {
            $scope.ngModel = section;
         });
      });

      $scope.instance = { name: $scope.name, uuid: $scope.uuid };


   }]);

