import personListTemplate from './personlist.html';

angular.module('org.saga.personlist', [
   'org.saga.service'
])

   .directive('personList', ['Person', 'PersonFactory', 'EvalFactory', function(Person, PersonFactory, EvalFactory) {
      return {
         restrict: 'E',
         template: personListTemplate,
         scope: {
            ngModel: '=',
            labels: '=',
            personIdentification: '=',
            onSelect: '&'
         },
         controller: ['$scope', function($scope) {

            $scope.getPersonIdentification = function(person) {
               return EvalFactory.run($scope.personIdentification, person);
            };

            $scope.getAddress = PersonFactory.address;

            $scope.selectPerson = function(person) {
               $scope.onSelect({ person: person });
            };

         }]
      };
   }]);
