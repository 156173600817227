import showHtmlTemplate from './showhtml.html';

angular.module('org.saga.widget.showhtml', [
   'org.saga.service',
   'ngSanitize'
])

   .directive('showhtmlWidget', ['PropertiesLoader', '$log', 'Template', 'Utils', function(PropertiesLoader, $log, Template, Utils) {
      return {
         restrict: 'E',
         template: showHtmlTemplate,
         scope: {
            widget: '=',
            instance: '=',
            humantask: '=?'
         },
         controller: ['$scope', '$sce', 'DefaultProperties', function($scope, $sce, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.showhtml);

            var timeoutContext = { timeout: null };

            $scope.generate = function() {
               var t = new Template();
               t.instance = $scope.instance;
               t.template = $scope.template;
               t.humanTask = $scope.humantask;
               t.$save(function(result) {
                  $scope.htmltext = $sce.trustAsHtml(result.response);
               }, function(error) {
                  $log.error(error.data);
               });
            };

            angular.forEach($scope.watching, function(w) {
               $scope.$watch('instance.attributes.' + w,
                  Utils.lateWatch(timeoutContext, 200, $scope.generate));
            });

            if (!$scope.watching || $scope.watching.length == 0) {
               $scope.generate();
            }
         }]
      };
   }]);
