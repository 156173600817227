import 'tinymce/tinymce'
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import 'tinymce/models/dom';
import 'tinymce/skins/ui/oxide/skin';
import 'tinymce/skins/ui/oxide/content';
import 'tinymce/skins/ui/oxide/content.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content';
import 'tinymce/plugins/advlist/plugin';
import 'tinymce/plugins/anchor/plugin';
import 'tinymce/plugins/autolink/plugin';
import 'tinymce/plugins/charmap/plugin';
import 'tinymce/plugins/code/plugin';
import 'tinymce/plugins/fullscreen/plugin';
import 'tinymce/plugins/image/plugin';
import 'tinymce/plugins/insertdatetime/plugin';
import 'tinymce/plugins/link/plugin';
import 'tinymce/plugins/lists/plugin';
import 'tinymce/plugins/media/plugin';
import 'tinymce/plugins/preview/plugin';
import 'tinymce/plugins/searchreplace/plugin';
import 'tinymce/plugins/table/plugin';
import 'tinymce/plugins/visualblocks/plugin';
import 'angular-ui-tinymce';
import richTextTemplate from './richtext.html';

angular.module('org.saga.widget.richtext', [
   'org.saga.service',
   'ui.tinymce'
])

   .directive('richtextWidget', function() {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: richTextTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            value: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', function($scope) {

            console.log($scope.editable);

            $scope.tinymceOptions = {
               plugins: 'advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table',
               readonly: !$scope.editable,
               promotion: false
            };

         }]
      };
   });
