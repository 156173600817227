import generatePDFTemplate from './generatepdf.html';

angular.module('org.saga.widget.generatepdf', [
   'org.saga.service'
])

   .directive('generatepdfWidget', ['$rootScope', 'GeneratePDF', 'PropertiesLoader', function($rootScope, GeneratePDF, PropertiesLoader) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: generatePDFTemplate,
         scope: {
            widget: '=',
            required: '=',
            editable: '=',
            instance: '=',
            output: '=?',
            onWidgetAction: '&'
         },
         controller: ['$scope', '$log', 'DefaultProperties', '$translate', 'Utils', function($scope, $log, DefaultProperties, $translate, Utils) {
            $scope.icons = { 'ok': 'done', 'error': 'bug_report', 'running': 'hourglass_empty', 'none': 'play_arrow' };
            $scope.status = 'none';
            let timeoutContext = { timeout: null };

            PropertiesLoader.load($scope, DefaultProperties.generatePDF);

            $scope.add = function(doc) {
               if (!$scope.output) {
                  $scope.output = [];
               }
               $scope.output.push(doc);
            };

            $scope.run = function() {
               const payload = {
                  file: $scope.fileName,
                  documentType: $scope.documentType,
                  template: $scope.templateId,
                  instance: $scope.instance
               };

               GeneratePDF.run({ instanceId: $scope.instance.instanceId }, payload, function(response) {
                  $scope.status = 'ok';
                  $scope.add(response);
               }, function() {
                  $scope.status = 'error';
               });
            };
         }]
      };
   }]);
