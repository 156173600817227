angular.module('org.saga.documentation.widget', [
   'org.saga.service',
   'org.saga.widget.admin.add'
])

   .controller('WidgetController', ['$scope', '$routeParams', '$log', 'Widget', 'DefaultProperties', 'InstanceExpression', 'WidgetFactory',
      function($scope, $routeParams, $log, Widget, DefaultProperties, InstanceExpression, WidgetFactory) {
         $scope.widgetName = $routeParams.widgetName;

         $scope.instance = { attributes: {} };

         var properties = angular.copy(DefaultProperties[$scope.widgetName]);

         $scope.section = { name: 'Preview' };
         $scope.screen = { name: 'Preview' };

         $scope.w = {
            ui: $scope.widgetName + '-widget',
            name: 'preview',
            label: 'Label',
            documentation: 'Documentation',
            help: '',
            offset: undefined,
            width: undefined,
            editable: {
               expression: 'true'
            },
            required: {
               expression: 'false'
            },
            visible: {
               expression: 'true'
            },
            binding: {},
            properties: {}
         };
         $scope.w.properties = properties;

         Widget.get({ name: $scope.widgetName }, function(widgetDef) {
            angular.forEach(widgetDef.bindings, function(b) {
               $scope.instance.attributes[b] = undefined;
               $scope.w.binding[b] = b;
            });
         });

         $scope.evaluateExpression = InstanceExpression.get;

         $scope.preview = function() {
            $scope.widgetHtml = WidgetFactory.generate($scope.w, {
               widgetAction: false,
               formLock: false,
               timestamp: true
            });
         };

         $scope.preview();

      }]);

