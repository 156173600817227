import historyTemplate from './history.html';
import 'angular-google-chart';

angular.module('org.saga.widget.history', [
   'org.saga.service',
   'googlechart'
])

   .value('googleChartApiConfig', {
      version: '1.1',
      optionalSettings: {
         packages: ['timeline'] //load just the package you want
      }
   })

   .directive('historyWidget', ['PropertiesLoader', '$log', 'Instance', 'Identity', '$q', function(PropertiesLoader, $log, Instance, Identity, $q) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: historyTemplate,
         scope: {
            widget: '=',
            instance: '=',
            humantasks: '=?'
         },
         controller: ['$scope', 'DefaultProperties', '$filter', function($scope, DefaultProperties, $filter) {

            PropertiesLoader.load($scope, DefaultProperties.history);

            var now = new Date();
            $scope.owners = {};
            $scope.htNames = [];

            Instance.humantasks({ instanceId: $scope.instance.instanceId }, function(humantasks) {
               $scope.humantasks = humantasks;

               $scope.myChartObject = {};

               $scope.translateColumnName = function(columnName) {
                  var msg = $filter('translate')(columnName);
                  return msg;
               };

               $scope.myChartObject.type = 'Timeline';

               $scope.myChartObject.data = {
                  'cols': [
                     { type: 'string', id: $scope.translateColumnName('HumanTask') },
                     { type: 'string', id: $scope.translateColumnName('Responsible') },
                     { type: 'date', id: $scope.translateColumnName('Started') },
                     { type: 'date', id: $scope.translateColumnName('Completed') }
                  ],
                  'rows': []
               };

               $scope.myChartObject.options = {
                  timeline: { colorByRowLabel: true },
                  groupByRowLabel: true,
                  avoidOverlappingGridLines: false
               };

               var promises = [];

               angular.forEach(humantasks, function(ht) {
                  if (ht.owner && ht.owner.name && !$scope.owners[ht.owner.name]) {
                     $scope.owners[ht.owner.name] = ht.owner.name;
                     promises.push(Identity.get({ name: ht.owner.name }, function(identity) {
                        $scope.owners[ht.owner.name] = identity;
                     }).$promise);
                  }
                  if ($scope.htNames.indexOf(ht.name) < 0) {
                     $scope.htNames.push(ht.name);
                  }
               });

               $q.all(promises).then(function() {
                  angular.forEach(humantasks, function(ht) {
                     var row = { c: [] };
                     row.c.push({ v: ht.label });
                     row.c.push({ v: $scope.getOwnerDisplayName(ht) });
                     row.c.push({ v: new Date(ht.createdAt) });
                     row.c.push({ v: (ht.completedAt) ? new Date(ht.completedAt) : now });

                     $scope.myChartObject.data.rows.push(row);
                  });
               });

            });

            $scope.getOwnerDisplayName = function(ht) {
               if (ht.owner && ht.owner.name) {
                  var identity = $scope.owners[ht.owner.name];
                  return ((identity) ? identity.displayName : 'N/A');
               }
               return 'N/A';
            };

            $scope.toDate = function(d) {
               if (d) {
                  return new Date(d);
               }
               return null;
            };

         }]
      };
   }]);
