import documentListTemplate from './documentlist.html';
import 'ng-file-upload';

angular.module('org.saga.widget.documentlist', [
   'org.saga.service',
   'org.saga.adddocument',
   'ngFileUpload'
])
   .directive('documentlistWidget', ['PropertiesLoader', '$http', '$sce', '$timeout', '$window', 'AddDocumentDialog', '$log', 'Upload', 'Document', 'EnumerationCache', 'InstanceExpression', 'ListUtils', 'Utils', 'EvalFactory', 'DownloadFile',
      function(PropertiesLoader, $http, $sce, $timeout, $window, AddDocumentDialog, $log, Upload, Document, EnumerationCache, InstanceExpression, ListUtils, Utils, EvalFactory, DownloadFile) {
         return {
            restrict: 'E',
            require: '^form',
            controllerAs: 'ctrl',
            template: documentListTemplate,
            scope: {
               widget: '=',
               editable: '=',
               instance: '=',
               documents: '=?',
               output: '=?',
               types: '=?'
            },
            link: function(scope, element, attrs, form) {
               scope.form = form;
            },
            controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

               PropertiesLoader.load($scope, DefaultProperties.documentlist);

               $scope.newDoc = { type: $scope.defaultType };
               $scope.previewDoc = null;
               $scope.selected = [];

               let timeoutContext = { timeout: null };

               $scope.setPreview = function(doc) {
                  if (!$scope.previewDoc) {
                     $scope.previewDoc = {
                        id: doc.id,
                        mimeType: doc.mimeType
                     };
                  } else if ($scope.previewDoc.id !== doc.id) {
                     $scope.previewDoc = {
                        id: doc.id,
                        mimeType: doc.mimeType
                     };
                  } else {
                     $scope.previewDoc = null;
                  }
               };

               $scope.loadAndFilter = function(documents) {
                  $scope.output = ListUtils.filter(documents, $scope.filter, function(item) {
                     return (InstanceExpression.eval($scope.filter, $scope.instance, item)); // jshint ignore:line
                  });
                  if ($scope.autoPreview && $scope.output && $scope.output.length > 0) {
                     const d = $scope.output[0];
                     $scope.setPreview(d);
                  }
               };

               $scope.refresh = function() {
                  if (!$scope.instance || !$scope.instance.instanceId) {
                     return;
                  }

                  if (!$scope.types) {
                     EnumerationCache.asArray('DocumentType', (types) => {
                        $scope.types = types;
                     });
                  }

                  $scope.loadAndFilter($scope.documents);
               };

               $scope.addDocument = () => {
                  AddDocumentDialog.show($scope.newDoc, $scope.types, $scope.allowedTypes, $scope.labels, (doc) => {
                     $scope.selectDocument(doc);
                  }, $scope.allowedTypes);
               };

               $scope.selectDocument = function(document) {
                  Upload.upload({
                     url: 'api/document/' + document.type,
                     file: document.file
                  }).then((resp) => {
                     $log.info('Upload document ok');
                     $scope.add(resp.data);
                  }, (error) => {
                     $log.error('Upload (new) document error' + error.data);
                  });
               };

               $scope.documentUrl = function(document) {
                  return $sce.trustAsResourceUrl('api/document/' + document.id + '/iframe');
               };

               $scope.newWindow = function(document) {
                  $http.get('api/document/' + document.id + '/content', { responseType: 'arraybuffer' }).then(function(result) {
                     const file = new Blob([result.data], { type: document.mimeType });
                     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(file, document.name);
                     } else {
                        const fileUrl = URL.createObjectURL(file);
                        const w = window.open(fileUrl, '_blank');
                        w.document.title = document.name;
                        $timeout(function() {
                           w.document.title = document.name;
                        }, 100);
                     }
                  });
               };

               $scope.updateDoc = function(doc) {
                  AddDocumentDialog.show(doc, $scope.allowedTypes, $scope.labels, function(newDoc) {
                     Upload.upload({
                        url: 'api/document/' + newDoc.type + '/' + doc.id,
                        file: newDoc.file
                     }).then(function(response) {
                        $scope.removeById(response.data);
                        $scope.add(response.data);
                        $log.info('Upload (update) document ok');
                     }, function(error) {
                        $log.error('Upload document error' + error.data);
                     });
                  }, $scope.allowedTypes);
               };

               $scope.deleteDoc = function(doc) {
                  Document.delete({ id: doc.id }, function() {
                     $scope.removeById(doc);
                     $scope.refresh();
                  });
               };

               $scope.add = function(doc) {
                  if (!$scope.documents) {
                     $scope.documents = [];
                  }
                  $scope.documents.push(doc);
                  $scope.refresh();
               };

               $scope.removeById = function(doc) {
                  angular.forEach($scope.documents, function(d, index) {
                     if (d.id === doc.id) {
                        $scope.documents.splice(index, 1);
                        return true;
                     }
                  });
               };

               $scope.getDocumentDetail = function(document) {
                  return EvalFactory.run($scope.documentDetail, document);
               };

               $scope.downloadDoc = (document) => {
                  const url = 'api/document/' + document.id + '/content';
                  DownloadFile.download(url, `${document.name}`, 'sagaDocument.doc', document.mimeType);
               };

               $scope.hidePreview = function() {
                  $scope.previewDoc = null;
               };

               $scope.refresh();

               angular.forEach($scope.watching, function(w) {
                  $scope.$watch('instance.attributes.' + w,
                     Utils.lateWatch(timeoutContext, 200, $scope.refresh));
               });

               $scope.findTypeLabel = (documentTypeName) => $scope.types?.find(type => type.name === documentTypeName)?.label;

            }]
         };
      }]);
