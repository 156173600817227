import commentsTemplate from './comments.html';

angular.module('org.saga.widget.comments', [
   'org.saga.service'
])

   .directive('commentsWidget', ['PropertiesLoader', 'Identity', '$log', '$rootScope', function(PropertiesLoader, Identity, $log, $rootScope) {
      return {
         restrict: 'E',
         require: '^form',
         controllerAs: 'ctrl',
         template: commentsTemplate,
         scope: {
            widget: '=',
            editable: '=',
            instance: '=',
            required: '=',
            comments: '=?'
         },
         link: function(scope, element, attrs, form) {
            scope.form = form;
         },
         controller: ['$scope', 'DefaultProperties', function($scope, DefaultProperties) {

            PropertiesLoader.load($scope, DefaultProperties.comments);

            $scope.showAll = false;
            $scope.currentLimit = $scope.numLimit;
            $scope.newComment = {};
            if (!$scope.comments) {
               $scope.comments = [];
            }

            $scope.clear = function() {
               $scope.newComment.comment = undefined;
               $scope.newComment.timestamp = undefined;
               Identity.get({ name: $rootScope.account.name }, function(user) {
                  $scope.newComment.user = user;
               });
            };

            $scope.clear();

            $scope.accountImageUrl = function() {
               return $rootScope.account ? $rootScope.account.imageUrl : undefined;
            };

            $scope.add = function() {
               $scope.newComment.timestamp = new Date();
               $scope.comments.splice(0, 0, angular.copy($scope.newComment));
               $scope.clear();
            };

            $scope.changeLimit = function() {
               $scope.currentLimit = ($scope.showAll) ? undefined : $scope.numLimit;
            };

         }]
      };
   }]);
