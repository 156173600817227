import companyListTemplate from './companylist.html';

angular.module('org.saga.companylist', [
   'org.saga.service'
])

   .directive('companyList', function() {
      return {
         restrict: 'E',
         template: companyListTemplate,
         scope: {
            ngModel: '=',
            onSelect: '&',
            labels: '='
         },
         controller: ['$scope', function($scope) {

            $scope.loading = false;

            $scope.selectCompany = function(company) {
               $scope.onSelect({ company: company });
            };

         }]
      };
   });
