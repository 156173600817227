import designTemplate from './design-menu.html';

angular.module('org.saga.designmenu', [
   'org.saga.service',
   'org.saga.addobject',
   'org.saga.addobjectwithrole',
   'org.saga.modelupload'
])

   .directive('designMenu', ['AuthSharedService', 'Instance', '$location', 'ModelUpload', '$route', function(AuthSharedService, Instance, $location, ModelUpload, $route) {
      return {
         restrict: 'E',
         controllerAs: 'ctrl',
         template: designTemplate,
         scope: {
            ngModel: '=',
            humantask: '=',
            instance: '=',
            screen: '='
         },
         controller: ['$scope', function($scope) {

            $scope.isDesigner = function() {
               return AuthSharedService.isAuthorized('DESIGNER');
            };

            $scope.toggleDesignMode = function() {
               $scope.ngModel.designMode = !$scope.ngModel.designMode;
            };

            $scope.toggleInvisibleWidgets = function() {
               $scope.ngModel.showInvisible = !$scope.ngModel.showInvisible;
            };

            $scope.toggleDebugMode = function() {
               $scope.ngModel.showDebug = !$scope.ngModel.showDebug;
            };

            $scope.showDesign = function() {
               $scope.ngModel.designMode = false;
               $location.path('/diagram/' + $scope.instance.name + '/' + $scope.instance.uuid);
            };

            $scope.showTask = function() {
               $scope.ngModel.designMode = false;
               $location.path('/diagram/' + $scope.instance.name + '/' + $scope.instance.uuid + '/' + $scope.humantask.name);
            };

            $scope.redeployFiles = function(files) {
               ModelUpload.run(files, function(data) {
                  var modelVersion = { name: data.name, uuid: data.uuid };
                  Instance.redeploy($scope.instance, modelVersion, function() {
                     $route.reload();
                  });
               });
            };

         }]
      };
   }]);
